import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Button, Typography} from "antd";
import {withRouter} from "react-router-dom";
import {
  getContact,
  getDefaults,
  getRepresentatives,
  getTerms,
  postContact
} from '../lib/serverCommunication';
import ActivityModal from './partials/ActivityModal';
import ContactCard from './partials/ContactCard';

const {Title} = Typography;

const baseBody = {
  "contactTitleId": 0,
  "name": "",
  "contactNameAlias": null,
  "firstName": "",
  "languageId": "nl-BE",
  "contactStatusId": 1,
  //"prospectionStatusId": 0,
  "contactOriginId": 0,
  "countryId": 1,
  "privateMobile": null,
  "privateEmail": null,
  "agreementComEmail": true,
  "agreementEmail": true,
  "agreementSms": true,
  "nationalNumber": null,
  "placeOfBirth": "",
  "bank": null,
  "bankaccount": null,
  "iban": null,
  "bic": null,
  "contactTypeIds": [],
  "representativeIds": [{
    "value": 8962
  }],
  "officeIds": [{
    "value": 2261
  }],
  "searchCriterias": [],
  "companies": [],
  "contactEmails": [],
  "contactNumbers": [],
  "estateAccounts": [],
  "documents": [],
  "rowStatus": 1,
  "statusId": 2,
};

class ContactPage extends Component {

  formRef = null;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      fieldData: {},
      data: {},
      id: '',
      showModal: false,
      representatives: [],
      terms: [],
      defaults: [],
    };
    if (props.match.params && props.match.params.id && props.match.params.id !== '0') {
      this.state.id = parseInt(props.match.params.id);
    } else {
      this.state.data = {...baseBody};
    }
  }

  loadRequiredData = async () => {
    const termsRequest = await getTerms();
    const defaultsRequest = await getDefaults();
    const representatives = await getRepresentatives();

    if(termsRequest.terms && defaultsRequest.defaults) {
      this.setState({terms: termsRequest.terms, defaults: defaultsRequest.defaults, representatives: representatives.Whise});
    }
  }

  loadContactIfNeeded = async () => {
    if(this.state.id) {
      return this.loadContact();
    }
    return {};
  };

  processContactIfNeeded = async (data) => {
    console.log(data);
    if(this.state.id) {
      return this.setState({data: {...data, contactTypes: data.contactTypeIds[0].value, statusId: data.contactStatusId}});
    }
    return;
  };

  componentDidMount() {
    this.loadRequiredData()
      .then(this.loadContactIfNeeded)
      .then(this.processContactIfNeeded)
      .then(this.loadData)
      .then((fieldData) => {
        if(!this.state.id) {
          const data = {...this.state.data};
          for(let key in fieldData) {
            data[key] = (fieldData[key].find(a => a.selected) || {}).id || baseBody[key];
          }
          this.setState({fieldData, loaded: true, data })
        } else {
          this.setState({fieldData, loaded: true})
        }
      });
  }

  loadContact = async () => {
    if(this.state.id) {
      const request = await getContact(this.state.id);
      return request.Whise;
    }
  };

  loadData = async () => {
    return this.state.defaults;
  }

  submitForm = async () => {
    const data = {...this.state.data};

    if(data.contactTypes) {
      data.contactTypeIds = [{value: parseInt(data.contactTypes)}];
      delete data.contactTypes;
    }
    if(!data.contactNameAlias)
      data.contactNameAlias = null;
    if(!data.firstName)
      data.firstName = null;
    if(data.contactOriginId)
      data.contactOriginId = parseInt(data.contactOriginId);
    if(data.contactTitleId)
      data.contactTitleId = parseInt(data.contactTitleId);
    if(data.statusId)
      data.statusId = parseInt(data.statusId);

    console.log(data);
    if(!data.name || !data.contactTitleId || !data.contactTypeIds) {
      this.setState({invalid: true});
    } else {
      const body = {
          ...baseBody,
          ...data
      };
      try {
        const response = await postContact(body);
        console.log(response);
        this.props.history.push('/contacts')
      } catch (e) {
        console.error(e);
      }
    }
  }

  handleProspectionStatusChange = (event) => {
    const value = event.currentTarget.options[event.currentTarget.selectedIndex].value;
    const id = parseInt(value) || this.state.fieldData.prospectionStatusId.find(e => e.selected).id;
    let statusId = 1;
    switch(id) {
      case 1066:
        statusId = 2;
        break;
      case 1064:
      case 1065:
        statusId = 0;
        break;
      default:
        break;
    }
    this.setState({data: {...this.state.data, statusId, prospectionStatusId: id}});
  }

  setInput = (key) => (event) => {
    this.setState({data: {...this.state.data, [key]: event.target.value}});
  }

  setSelect = (key) => (event) => {
    this.setState({data: {...this.state.data, [key]: event.target.selectedOptions[0].value}});
  }

  render() {
    const {fieldData, representatives, terms, defaults, loaded, data, id, showModal} = this.state;
    const {setInput, setSelect} = this;

    if(!loaded) return <Fragment />;

    return (
      <Fragment>
        {
          showModal && <ActivityModal contact={data} onClose={() => this.setState({showModal: false})} />
        }
        <Title>
          Contact
          {!id ? ' toevoegen' : ''}
        </Title>
        {
          !!id && loaded ? (
            <ContactCard contact={data} fields={defaults} terms={terms} representatives={representatives} />
          ) : (
            <form onSubmit={(e) => {e.preventDefault(); return false;}} style={{display: loaded ? 'block' : 'none'}}>

              <div className="section-content has-title is-editable" data-bind="click: sectionGeneralInfo.select">
                <div className="section-title icon-middle" data-bind="with: sectionGeneralInfo">
                  <a data-bind="click: expand, css: { 'selected': isExpanded() }" className="selected">
                    <i className="fa fa-angle-down fa-lg toggle-arrow"
                       data-bind="css: {'fa-angle-down': isExpanded(), 'fa-angle-up': !isExpanded()}"></i>
                    <span data-bind="text: name" className="capitalize">Algemene info</span>
                  </a>
                </div>
                <div data-bind="visible: sectionGeneralInfo.isExpanded()">
                  <div data-bind="visible: sectionGeneralInfo.isSelected()">
                    <div className="row">
                      <div className="col-sm-2 form-group" data-bind="validationElement: name" data-orig-title="">
                        <label htmlFor="name" className="capitalize">
                          <span data-i18n="Name">Naam</span>
                        </label>
                      </div>
                      <div className="col-sm-5 form-group">
                        <input type="text" id="name" data-bind="value: name" data-i18n="[placeholder]LastName"
                               className="form-control" placeholder="Familienaam" defaultValue={data.name} onChange={setInput('name')} />
                      </div>
                      <div className="col-sm-5 form-group">
                        <input type="text" id="firstName" data-bind="value: firstName"
                               data-i18n="[placeholder]FirstName" className="form-control" placeholder="Voornaam" value={data.firstName} onChange={setInput('firstName')} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="alias" className="capitalize">
                          <span data-i18n="Alias">Alias (Naam 2)</span>
                        </label>
                      </div>
                      <div className="col-sm-5 form-group">
                        <input type="text" id="alias" data-bind="value: contactNameAlias" data-i18n="[placeholder]Alias"
                               className="form-control" placeholder="Alias (Naam 2)"  defaultValue={data.contactNameAlias} onChange={setInput('contactNameAlias')} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="title" data-i18n="Title" className="capitalize">Titel</label>
                      </div>
                      <div className="col-sm-10 form-group">
                        <select id="title"  className="form-control select2-hidden-accessible"
                                data-bind="options: contactTitleSelection, optionsCaption: i18next.t('ContactTitle'), value: contactTitleId, optionsText: 'name', optionsValue: 'id', select: { }"
                                tabIndex="-1" aria-hidden="true" value={data.contactTitleId} onChange={setSelect('contactTitleId')}>
                          <option value="">Aanspreektitel</option>
                          {
                            loaded && fieldData.contactTitleId.map(e => (<option key={e.id} value={e.id}>{e.text}</option>))
                          }
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="privateMobile" className="capitalize">
                          <span data-i18n="Alias">Telefoonnummer</span>
                        </label>
                      </div>
                      <div className="col-sm-5 form-group">
                        <input type="text" id="privateMobile"
                               className="form-control" placeholder="Telefoonnummer"  defaultValue={data.privateMobile} onChange={setInput('privateMobile')} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="privateEmail" className="capitalize">
                          <span data-i18n="Alias">Emailadres</span>
                        </label>
                      </div>
                      <div className="col-sm-5 form-group">
                        <input type="text" id="privateEmail"
                               className="form-control" placeholder="Emailadres"  defaultValue={data.privateEmail} onChange={setInput('privateEmail')} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="address1" data-i18n="Address" className="capitalize">Adres</label>
                      </div>
                      <div className="col-sm-6 form-group">
                        <input type="text" data-bind="textInput: address1" data-i18n="[placeholder]Street"
                               className="form-control address-autocomplete pac-target-input" placeholder="Straat"
                               autoComplete="off"  defaultValue={data.address1} onChange={setInput('address1')} />
                      </div>
                      <div className="col-sm-2 form-group">
                        <input type="text" id="number" data-bind="value: number" data-i18n="[placeholder]AddressNumber"
                               className="form-control" placeholder="Nummer" defaultValue={data.number} onChange={setInput('number')} />
                      </div>
                      <div className="col-sm-2 form-group">
                        <input type="text" id="flatNumber" data-bind="value: box" data-i18n="[placeholder]Box"
                               className="form-control" placeholder="Bus" defaultValue={data.box} onChange={setInput('box')} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="zipcode" className="capitalize">
                          <span data-i18n="Zip">Postcode</span> /
                          <span data-i18n="City">Stad</span>
                        </label>
                      </div>
                      <div className="col-sm-5 form-group">
                        <input type="text" id="zipcode" data-i18n="[placeholder]Zip"
                               data-bind="jqAuto: { source: zipCityAutocomplete.findZip, value: zip, valueProp: 'zip', dataValue: selectedZipCity, template : 'zipcity-item-template', options: { delay: 250 } }"
                               className="form-control ui-autocomplete-input" autoComplete="off" placeholder="Postcode" defaultValue={data.zip} onChange={setInput('zip')} />
                      </div>
                      <div className="col-sm-5 form-group">
                        <input type="text" id="city" data-i18n="[placeholder]City"
                               data-bind="jqAuto: { source: zipCityAutocomplete.findCity, value: city, valueProp: 'city', dataValue: selectedZipCity, template : 'zipcity-item-template', options: { delay: 250 } }"
                               className="form-control ui-autocomplete-input" autoComplete="off" placeholder="Stad" defaultValue={data.city} onChange={setInput('city')} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="language" data-i18n="Language" className="capitalize"
                               data-bind="validationElement: languageId" data-orig-title="">Taal</label>
                      </div>
                      <div className="col-sm-10 form-group">
                        <select id="language"  className="form-control select2-hidden-accessible"
                                data-bind="options: languageSelection, optionsCaption: i18next.t('Language'), value: languageId, optionsText: 'name', optionsValue: 'id', select: { }"
                                tabIndex="-1" aria-hidden="true" value={data.languageId} onChange={setSelect('languageId')}>
                          <option value="">Taal</option>
                          <option value="en-GB">Engels</option>
                          <option value="nl-BE">Nederlands</option>
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="typeOfContact" data-i18n="TypeOfContact" className="capitalize"
                               data-bind="validationElement: contactTypes" data-orig-title="">Contacttype</label>
                      </div>
                      <div className="col-sm-10 form-group">
                        <select id="typeOfContact"  className="form-control select2-hidden-accessible"
                                data-bind="value: contactTypes"
                                multiple="" tabIndex="-1" aria-hidden="true" value={data.contactTypes} onChange={setSelect('contactTypes')}>
                          <option value="">Contacttype</option>
                          {
                            loaded && fieldData.contactTypes.map(e => (<option key={e.id} value={e.id}>{e.text}</option>))
                          }
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="source" data-i18n="ContactOrigin" className="capitalize">Contact oorsprong</label>
                      </div>
                      <div className="col-sm-10 form-group">
                        <select id="source"  className="form-control select2-hidden-accessible"
                                data-bind="options: contactOriginSelection, value: contactOriginId, optionsCaption: i18next.t('ContactOrigin'), optionsText: 'name', optionsValue: 'id', select: {}"
                                tabIndex="-1" aria-hidden="true" value={data.contactOriginId} onChange={setSelect('contactOriginId')}>
                          <option value="">Contact oorsprong</option>
                          {
                            loaded && fieldData.contactOriginId.map(e => (<option key={e.id} value={e.id}>{e.text}</option>))
                          }
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="prospectionStatus" data-i18n="ProspectionStatus"
                               className="capitalize">Prospectiestatus</label>
                      </div>
                      <div className="col-sm-10 form-group">
                        <select id="prospectionStatus"
                                className="form-control select2-hidden-accessible"
                                data-bind="options: prospectionStatusSelection, value: prospectionStatusId, optionsCaption: i18next.t('ProspectionStatus'), optionsText: 'name', optionsValue: 'id', select: {}"
                                tabIndex="-1" aria-hidden="true" onChange={(event) => {
                          this.handleProspectionStatusChange(event);
                        }} value={data.prospectionStatusId}>
                          <option value="">Prospectiestatus</option>
                          {
                            loaded && fieldData.prospectionStatusId.map(e => (<option key={e.id} value={e.id}>{e.text}</option>))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 form-group">
                        <label htmlFor="status" data-i18n="Status" className="capitalize"
                               data-bind="validationElement: statusId" data-orig-title="">Status</label>
                      </div>
                      <div className="col-sm-10 form-group">
                        <select id="status" className="form-control select2-hidden-accessible"
                                data-bind="options: statusSelection, optionsCaption: i18next.t('Status'), value: statusId, optionsText: 'name', optionsValue: 'id', select: {}"
                                tabIndex="-1" aria-hidden="true" value={data.statusId} onChange={setSelect('statusId')}>
                          <option value="">Status</option>
                          <option value="0">gearchiveerd</option>
                          <option value="1">normaal</option>
                          <option value="2">hangend</option>
                          <option value="3">gewist</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Button size="large" onClick={this.submitForm}>Opslaan</Button>
            </form>
          )
        }
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactPage));
