import {SET_CONTACT_SELECTION, SET_ESTATE_SELECTION} from "../actions/Actions";

const initialState = {
    estateId: 0,
    estateName: '',
    contactId: 0,
    contactName: ''
};

const config = (state = {...initialState}, action) => {
    switch (action.type) {
        case SET_CONTACT_SELECTION:
            const {contactId, contactName} = action.payload;
            return {
                ...state,
                contactId,
                contactName
            };
        case SET_ESTATE_SELECTION:
            const {estateId, estateName} = action.payload;
            return {
                ...state,
                estateId,
                estateName
            };
        default:
            return state;
    }
};

export default config;


