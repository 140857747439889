import {Button, Card, Icon} from 'antd';
import React, {useEffect, useState} from 'react';
import ObjectRender from './ObjectRender';
import {getActivities} from '../../lib/serverCommunication';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {setContact} from '../../redux/actions/Actions';
import {CheckOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import ActivityModal from './ActivityModal';

const fieldsIncluded = ['name', 'firstName', 'contactStatusId', 'contactTitleId', 'contactTypeIds', 'privateEmail', 'privateMobile', 'representativeIds'];

const ContactCard = ({contact, terms, fields, representatives, selectedContactId, setContact}) => {
  const [showAll, setShowAll] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [activities, setActivites] = useState({Activiteiten: []});
  const [activitiesLoaded, setActivitiesLoaded] = useState(false);
  const history = useHistory();

  useEffect(
    () => {
      (
        async () => {
          const data = await getActivities(contact.id);
          if(data.Whise && data.Whise.history && data.Whise.history.responseData)
            setActivites({Activiteiten: data.Whise.history.responseData});
          setActivitiesLoaded(true);
        }
      )();
    }, [contact, activitiesLoaded]
  );

  if(!contact) return (<></>);

  const filtered = Object.fromEntries(fieldsIncluded.map(key => [key, contact[key]]));
  const rest = Object.fromEntries(Object.keys(contact).filter(key => fieldsIncluded.indexOf(key) === -1).map(key => [key, contact[key]]));


  const handleActivityEstateContact = (key, value) => {
    console.log(key, value)
    if(key === 'userIds')
      return '';
    if((key !== 'contacts' && key !== 'estates') || !Array.isArray(value) || value.length === 0)
      return false;


    if(key === 'contacts')
      return <Button onClick={() => history.push(`/contacts/${value[0].value}`)}>Ga naar contact</Button>

    if(key === 'estates')
      return value[0].value;
  };

  return (
    <>
      {
        showForm && <ActivityModal contact={contact} onClose={() => setShowForm(false)} />
      }
      <Card title={`${contact.name}, ${contact.firstName || '-'}`} extra={
        contact.id !== selectedContactId ?
          <Button shape="circle" onClick={() => setContact(contact.id, `${contact.name}, ${contact.firstName || '-'}`)}>
            <MinusOutlined/>
          </Button> :
          <Button shape="circle" type="primary">
            <CheckOutlined/>
          </Button>
      }>
        <ObjectRender object={filtered} terms={terms} fields={fields} representatives={representatives} />

        <div style={{fontSize: 16}}  onClick={() => setShowAll(!showAll)} >
          <Icon type={showAll ? 'minus' : 'plus'} style={{fontSize: 16, color: '#232856', marginRight: 12}} />
          Bekijk alle gegevens
        </div>
      </Card>

      {
        activitiesLoaded ?
          <Card title={"Historiek"} extra={
            <Button className="vertical-align-middle" type="primary"  title="+" onClick={()=>setShowForm(true)}>
              <PlusOutlined/> Historiek
            </Button>}>
            <ObjectRender object={{
              Activiteiten: (activities.Activiteiten || []).map(a => {
              delete a.userIds;
              delete a.categoryId;
              delete a.actionTypeId;
              return a;
            })}} terms={terms} fields={fields} representatives={representatives} specialHandling={handleActivityEstateContact} />
          </Card> : null
      }

      {
        showAll ? (
          <Card title={"Alle gegevens"}>
            <ObjectRender object={rest} terms={terms} fields={fields} representatives={representatives}/>
          </Card>
        ) : null
      }
    </>
    )
}

const mapStateToProps = state => ({
  selectedContactId: state.config.contactId,
});
const mapDispatchToProps = dispatch => ({
  setContact: (id, name) => dispatch(setContact(id, name))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactCard);
