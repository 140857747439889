import React, {Component} from 'react';
import {Layout, Icon} from 'antd';
import {withRouter} from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import {connect} from "react-redux";
import {logoutUser} from "../redux/actions/Actions";
import Flex from "../components/layout/Flex";
import "../scss/header.scss";
import CurrentSelections from '../pages/partials/CurrentSelections';

const {Header,  Content} = Layout;

class DefaultLayout extends Component {

    state = {
        collapsedState: true,
    };

    toggleCollapsed = () => this.setState({collapsedState: !this.state.collapsedState});

    render() {
        const {collapsedState} = this.state;
        const {toggleCollapsed} = this;

        return (
            <Layout>
                <SidebarMenu collapsed={collapsedState} toggleCollapse={toggleCollapsed}
                             verifying={this.props.verifying}/>
                <Layout style={{marginLeft: collapsedState ? 80 : 200}}>
                    <Header style={{background: '#fff', padding: 0}}>
                        <Flex row alignItems={'center'}>
                            <Flex>
                                <Icon
                                  className="trigger"
                                  type={collapsedState ? 'menu-unfold' : 'menu-fold'}
                                  onClick={toggleCollapsed}
                                />
                            </Flex>
                            <Flex grow={1}>
                            </Flex>
                            <Flex justifyContent={'flex-end'}>
                                <Icon
                                    className="trigger"
                                    type={'logout'}
                                    onClick={this.props.logout}
                                />
                            </Flex>
                        </Flex>

                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            background: '#fff',
                        }}
                    >
                        {this.props.children}
                    </Content>
                    <CurrentSelections />
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
