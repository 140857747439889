import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Col, Collapse, Divider as AntdDivider, Input, Row, Select, Spin, Switch, Typography} from "antd";
import {withRouter} from "react-router-dom";
import {getDefaults, getRepresentatives, getTerms, postSearch} from '../lib/serverCommunication';
import Menu from 'antd/lib/menu';
import {HomeOutlined, UserOutlined} from '@ant-design/icons';
import ContactCard from './partials/ContactCard';
import EstateCard from './partials/EstateCard';

const {Title, Text} = Typography;
const { Panel } = Collapse;
const { SubMenu, Item } = Menu;
const { Option } = Select;

const Divider = () => <AntdDivider style={{marginTop: 8, marginBottom: 8}} />

class SearchPage extends Component {

    state = {
      estates: [],
      contacts: [],
      representatives: [],
      specificity: 1,
      loading: true,
      searched: false,
      filters: {
        showEstates: true,
        showContacts: true,
        categoryId: 1,
        subcategoryId: 1,
        contactStatusId: 2,
        price: '40-50',
      },
      terms: [],
      defaults: [],
      active: {
        type: 'none',
        id: 0,
      }
    };

    componentDidMount() {
      this.loadRequiredData().then(()=>{});
    }

    async loadRequiredData() {
      const termsRequest = await getTerms();
      const defaultsRequest = await getDefaults();
      const representatives = await getRepresentatives();

      if(termsRequest.terms && defaultsRequest.defaults) {
        this.setState({terms: termsRequest.terms, defaults: defaultsRequest.defaults, representatives: representatives.Whise, loading: false});
      }
    }

  search = async (value) => {
      this.setState({loading: true, active: {type: 'none', id: 0}});
      const results = await postSearch({query: value});

      if(results && results.Whise) {
        console.log(results.Whise);
        this.setState({...results.Whise, loading: false, searched: true});
      }
    };

    genFilters = (set, type) => {
      const {terms, defaults} = this.state;
      const additionalEstateKeys = ['zip','city','rooms','bathrooms','groundArea','area','garden','terrace','parking','garage','fronts','gardenArea']
      if(set.length) {
        const keys = [...new Set(set.flatMap(s => Object.keys(s)))];
        return (
          <Row>
            {
              keys.filter(key => !!defaults[key]).map(key => (
                <Col key={key} xs={24} md={12} lg={6} xl={4} style={{flexDirection: 'column'}}>
                  <Text>{terms.find(t => t.field === key)?.friendly || key}</Text>
                  <Select
                    showSearch
                    style={{ width: '90%' }}
                    placeholder={terms.find(t => t.field === key)?.friendly || key}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(value) => {
                      console.log(value);
                      this.setState({filters: {...this.state.filters, [key]: value || undefined}})
                    }}
                    value={this.state.filters[key]}
                  >
                    <Option value="">Kies {terms.find(t => t.field === key)?.friendly || key}</Option>
                    {
                      defaults[key].map(d => (<Option key={d.id} value={d.id}>{d.text}</Option>))
                    }
                  </Select>
                </Col>
              ))
            }
            {
              type === 'estates' ? (
                <Fragment>
                  <Col xs={24} md={12} lg={6} xl={4} style={{flexDirection: 'column'}}>
                    <Text>Prijs</Text>
                    <Select
                      style={{ width: '90%' }}
                      placeholder="Prijs"
                      optionFilterProp="children"
                      onChange={(value) => {
                        this.setState({filters: {...this.state.filters, price: value || undefined}})
                      }}
                      value={this.state.filters.price}
                    >
                      <Option value="">Kies prijsbereik</Option>
                      <Option value="0-20">tot 20.000</Option>
                      <Option value="20-30">20.000 - 30.000</Option>
                      <Option value="30-40">30.000 - 40.000</Option>
                      <Option value="40-50">40.000 - 50.000</Option>
                      <Option value="50-60">50.000 - 60.000</Option>
                      <Option value="60-80">60.000 - 80.000</Option>
                      <Option value="80-100">80.000 - 100.000</Option>
                      <Option value="100-125">100.000 - 125.000</Option>
                      <Option value="125-150">125.000 - 150.000</Option>
                      <Option value="150-175">150.000 - 175.000</Option>
                      <Option value="175-200">175.000 - 200.000</Option>
                      <Option value="200-250">200.000 - 250.000</Option>
                      <Option value="250-300">250.000 - 300.000</Option>
                      <Option value="300-400">300.000 - 400.000</Option>
                      <Option value="400-500">400.000 - 500.000</Option>
                      <Option value="500-9999">50.000 of meer</Option>
                    </Select>
                  </Col>
                  {
                    additionalEstateKeys.map(key => (
                      <Col key={key} xs={24} md={12} lg={6} xl={4} style={{flexDirection: 'column'}}>
                        <Text>{terms.find(t => t.field === key)?.friendly || key}</Text>
                        <Select
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          style={{ width: '90%' }}
                          placeholder={terms.find(t => t.field === key)?.friendly || key}
                          onChange={(value) => {
                            console.log(value);
                            this.setState({filters: {...this.state.filters, [key]: value || undefined}})
                          }}
                          value={this.state.filters[key]}
                        >
                          <Option value="">Kies {terms.find(t => t.field === key)?.friendly || key}</Option>
                          {
                            [...(new Set(set.map(e => e[key]).filter(v => !!v)))].sort().map((d,i)=> (<Option key={`${key}-${i}`} value={d}>{d}</Option>))
                          }
                        </Select>
                      </Col>
                    ))
                  }
                </Fragment>
              ) : null
            }
          </Row>
        )
      }
      return null;
    };

    filter = (set, type) => {
      return set.filter(entry => {
        if(!entry) return false;
        if(type === 'estates' && this.state.filters.price) {
          const min = parseInt(this.state.filters.price.split('-')[0]) * 1000;
          const max = parseInt(this.state.filters.price.split('-')[1]) * 1000;
          if(entry.price < min || entry.price > max)
          return false;
        }
        return Object.keys(this.state.filters).filter(k => k !== 'price').reduce((acc, key) => {
          if(!this.state.filters[key] || !entry[key] || entry[key] === this.state.filters[key]) return acc;
          return false;
        }, true)
      })
    };

    renderName = (item, type) => {
      console.log(item, type)
      if(!item) return null;

      if (type === 'estates') {
        return `${item.name} - ${item.address1 || '-'} ${item.number || '-'}, ${item.zip || '-'} ${item.city || '-'}`;
      } else if (type === 'contacts') {
        return `${item.name}, ${item.firstName || '-'}${item.privateEmail ? ` (${item.privateEmail})` : ''}`;
      } else {
        return null;
      }
    }

    render() {
        const {estates, contacts, specificity, loading, filters, searched, active} = this.state;
        const estatesFiltered = this.filter(estates, 'estates');
        const contactsFiltered = this.filter(contacts, 'contacts');
        return (
            <Fragment>
              <Title>Zoeken in Whise</Title>
              <Row className="containerPadding">
                <Col>
                  <Text>Vanwege beperkingen in Whise kunnen we slechts 1000 contacten en panden tegelijk filteren.</Text>
                </Col>
                <Col>
                  <Text>Op basis van de gekozen tekstfilter tonen we nu {(specificity * 100).toFixed(1)}% van de resultaten.</Text>
                </Col>
              </Row>
              <Row className="containerPadding">
                <Input.Search
                  placeholder="Zoeken..."
                  allowClear
                  enterButton="Zoek"
                  size="large"
                  onSearch={this.search}
                />
              </Row>
              <Row className="containerPadding">
                {
                  loading ? <Spin size="large" /> : (
                    <>
                      {
                        searched ? (
                          <>
                            <Collapse defaultActiveKey={[]}>
                              <Panel header="Types filteren" key="1">
                                <Row>
                                  <Col xs={12} md={8} lg={4} xl={3}>
                                    Toon contacten
                                  </Col>
                                  <Col xs={12} md={16} lg={20} xl={21}>
                                    <Switch checked={filters.showContacts} onChange={(state) => this.setState({showContacts: state})} />
                                  </Col>
                                </Row>
                                <Divider />
                                <Row>
                                  <Col xs={12} md={8} lg={4} xl={3}>
                                    Toon panden
                                  </Col>
                                  <Col xs={12} md={16} lg={20} xl={21}>
                                    <Switch checked={filters.showEstates} onChange={(state) => this.setState({showEstates: state})} />
                                  </Col>
                                </Row>
                              </Panel>
                              <Panel header="Contact filters" key="2">
                                {this.genFilters(contacts, 'contacts')}
                              </Panel>
                              <Panel header="Pand filters" key="3">
                                {this.genFilters(estates, 'estates')}
                              </Panel>
                            </Collapse>
                            <Row>
                              <Col xs={12} lg={8}>
                                <Menu
                                  style={{ width: '100%' }}
                                  defaultOpenKeys={[]}
                                  mode="inline"
                                >
                                  {
                                    filters.showContacts ? (
                                      <SubMenu key="contacts" icon={<UserOutlined />} title={`Contacten (${contactsFiltered.length})`}>
                                        {
                                          contactsFiltered.length ?
                                            contactsFiltered
                                              .map(
                                              c => <Item key={c.id} onClick={() => this.setState({active: {type: 'contacts', id: c.id}})}>{this.renderName(c, 'contacts')}</Item>
                                            ) : <Item>Geen resultaten</Item>
                                        }
                                      </SubMenu>
                                    ) : null
                                  }
                                  {
                                    filters.showEstates ? (
                                      <SubMenu key="estates" icon={<HomeOutlined />} title={`Panden (${estatesFiltered.length})`}>
                                        {
                                          estatesFiltered.length ?
                                            estatesFiltered
                                              .map(
                                                estate => <Item key={estate.id} onClick={() => this.setState({active: {type: 'estates', id: estate.id}})}>{this.renderName(estate, 'estates')}</Item>
                                              ) : <Item>Geen resultaten</Item>
                                        }
                                      </SubMenu>
                                    ) : null
                                  }
                                </Menu>
                              </Col>
                              <Col xs={12} lg={16}>
                                {
                                  active.type !== 'none' && active.id !== 0 ? (
                                    <>
                                      {
                                        active.type === 'contacts' ?
                                          <ContactCard contact={this.state[active.type].find(e => e.id === active.id)} terms={this.state.terms} fields={this.state.defaults} representatives={this.state.representatives} /> :
                                          <EstateCard estate={this.state[active.type].find(e => e.id === active.id)} terms={this.state.terms} fields={this.state.defaults} representatives={this.state.representatives} />
                                      }
                                    </>
                                  ) : null
                                }
                              </Col>
                            </Row>
                          </>
                        ) : null
                      }
                    </>
                  )
                }
              </Row>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchPage));
