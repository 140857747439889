import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
  Alert,
  Button,
  Col,
  DatePicker, List,
  Row,
  Spin, Switch,
  Table,
  Typography
} from "antd";
import {withRouter} from "react-router-dom";
import {
  getDefaults,
  getEstateChildren,
  getEstateParents,
  getLeads,
  getRepresentatives,
  getTerms
} from '../lib/serverCommunication';
import * as moment from 'moment';
import { CSVLink } from "react-csv";

const {Title} = Typography;


class LeadsPage extends Component {

    state = {
      periods: [],
      estates: [],
      contacts: [],
      activities: [],
      representatives: [],
      loading: true,
      from: moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'),
      to: moment().startOf('month').format('YYYY-MM-DD'),
      comparisonDate: moment().startOf('day').format('YYYY-MM-DD'),
      terms: [],
      defaults: [],
      groupProjects: true,
      groupRepresentatives: true,
      onlyOnline: true,
      onlineUnderOffline: false,
      useSetTimeFrames: false,
      lastSettings: "",
    };

    componentDidMount() {
      this.loadRequiredData().then(()=>{});
    }

    async loadRequiredData() {
        const termsRequest = await getTerms();
        const defaultsRequest = await getDefaults();
        const representatives = await getRepresentatives();

        if(termsRequest.terms && defaultsRequest.defaults) {
            this.setState({terms: termsRequest.terms, defaults: defaultsRequest.defaults, representatives: representatives.Whise, loading: false});
        }
    }

  search = async () => {
      const {from, to, comparisonDate, loading, useSetTimeFrames} = this.state;
      const getDataForPeriod = async (from, to) => {
        console.log(from, to)
        const requestData = {estates: {}, history: [], contacts: {}};
        let startDate = moment(from);
        const endDate = moment(to);
        while(startDate.clone().isBefore(endDate)) {
          let requestEnd = startDate.clone().add(2, 'month');
          if(requestEnd.isAfter(endDate)) requestEnd = endDate;

          const results = await getLeads(startDate.format('YYYY-MM-DD'), requestEnd.format('YYYY-MM-DD'));
          if(results && results.Whise) {
            requestData.estates = {...requestData.estates, ...results.Whise.estates};
            requestData.contacts = {...requestData.contacts, ...results.Whise.contacts};
            requestData.history = [...requestData.history, ...results.Whise.history.responseData];
          }

          startDate = requestEnd.clone();
        }

        console.log(requestData);
        const data = await this.processData(requestData.estates, requestData.history, requestData.contacts);
        return {data, estates: requestData.estates, activities: requestData.history, contacts: requestData.contacts};
      }
      if(!loading) {
        this.setState({loading: true, periods: [], lastSettings: this.getSettingsState()});
        if(!useSetTimeFrames) {
          const {data, estates, contacts, activities} = await getDataForPeriod(from, to);
          this.setState({data, estates, contacts, activities, loading: false, periods: [{from: moment(from).format('DD-MM-YYYY'),to: moment(to).format('DD-MM-YYYY')}]});
        } else {
          const compareMoment = moment(comparisonDate);
          const {data, estates, contacts, activities} = await getDataForPeriod(compareMoment.clone().startOf('year'), comparisonDate);
          const monthData = (await getDataForPeriod(compareMoment.clone().add(-1, 'month').startOf('month'), compareMoment.clone().add(-1, 'month').startOf('month').add(1, 'month'))).data;
          const quarterData = (await getDataForPeriod(compareMoment.clone().add(-1, 'Q').startOf('Q'), compareMoment.clone().add(-1, 'Q').startOf('Q').add(1, 'Q'))).data;
          console.log(data, monthData, quarterData);
          data.forEach(row => {
            const month = monthData.find(e => e.estateId === row.estateId);
            if(month) {
              row.leadsMonth = month.leads;
              row.ratioMonth = month.estateCount ?
                (Math.round((month.leads / month.estateCount) * 10) / 10).toFixed(1).replace('.',',') : '0';
            } else {
              row.leadsMonth = 0;
              row.ratioMonth = '0';
            }
            const quarter = quarterData.find(e => e.estateId === row.estateId);
            if(quarter) {
              row.leadsQuarter = quarter.leads;
              row.ratioQuarter = quarter.estateCount ?
                (Math.round((quarter.leads / quarter.estateCount) * 10) / 10).toFixed(1).replace('.',',') : '0';
            } else {
              row.leadsQuarter = 0;
              row.ratioQuarter = '0';
            }
          });
          this.setState({data, estates, contacts, activities, loading: false, periods: [
              {from: compareMoment.clone().startOf('year').format('DD-MM-YYYY'),to: moment(comparisonDate).format('DD-MM-YYYY')},
              {from: compareMoment.clone().add(-1, 'month').startOf('month').format('DD-MM-YYYY'),to: compareMoment.clone().add(-1, 'month').startOf('month').add(1, 'month').format('DD-MM-YYYY')},
              {from: compareMoment.clone().add(-1, 'Q').startOf('Q').format('DD-MM-YYYY'), to: compareMoment.clone().add(-1, 'Q').startOf('Q').add(1, 'Q').format('DD-MM-YYYY')},
          ]});

        }
      }
    };

    processData = async (estates, activities, contacts) => {

      const {groupProjects, groupRepresentatives, onlyOnline, onlineUnderOffline} = this.state;

      const perEstate = {};

      activities.forEach(a => {
        if(a.estates && a.estates.length) {
          if ([28092,28362,28799,28835,30725,20484,20485,20487,20489,20490,20491,20495,20496,20497].indexOf(a.actionTypeId || 0) > -1) return;
          //[50, 46, 47, 48, 49, 43, 41, 54, 64, 72, 1]
          if ([43, 41].indexOf(a.categoryId) === -1) return;
          a.estates.forEach(e => {
            if (!perEstate[e.value]) {
              perEstate[e.value] = [];
            }
            if (a.contacts && a.contacts.length) {
              a.contacts.forEach(c => perEstate[e.value].push(c.value));
            }
          })
        }
      });

      const estateIds = [...(new Set(Object.keys(perEstate)))];
      const estateData = [];
      for(let i = 0; i < estateIds.length; i = i + 20) {
        const partialData = await getEstateParents(estateIds.slice(i,i + Math.min(20, estateIds.length - i)));
        if(partialData.Whise)
          estateData.push(...partialData.Whise);
      }
      const estateMap = Object.fromEntries(estateData.map(e => ([e.id, e])));

      console.log('perEstate[4095999]',perEstate[4095999])


      if(groupProjects) {
        const missingParents = [];
        Object.keys(perEstate).forEach(key => {
          const estate = estateMap[key] || {};
          if(estate.parentId) {
            //perEstate[estate.parentId] = [...(perEstate[estate.parentId] || []), ...(perEstate[key])];
            //delete perEstate[key];
            //perEstate[key] = [];
            if(!estateMap[estate.parentId]) {
              missingParents.push(estate.parentId);
            }
          }
        });
        if(missingParents.length > 0) {
          const parentData = await getEstateParents(missingParents);
          for(let parent of parentData.Whise) {
            estateMap[parent.id] = parent;
          }
        }

        const parents = Object.keys(estateMap).filter(key => estateMap[key].isParent).map(key => key);

        for(let i = 0; i < parents.length; i++) {
          const childrenData = await getEstateChildren([parents[i]]);
          if(Array.isArray(childrenData.Whise)) {
            for(let child of childrenData.Whise) {
              if(!estateMap[child.id])
                estateMap[child.id] = child;
            }
          }
        }

        Object.keys(estateMap).forEach(key => {
          const estate = estateMap[key];
          if(estate.isParent) {
            const count = Object.keys(estateMap).filter(k => estateMap[k].parentId === estate.id && (estateMap[k].purposeStatusId === 1 || estateMap[k].purposeStatusId === 5)).length;
            estate.estateCount = count;
          }
        })
      }

      if(groupProjects && onlyOnline && !onlineUnderOffline) {
        estateData.filter((e) => e.displayStatusId === 1 || e.statusId !== 1).forEach((e) => {
          delete perEstate[e.id];
        });
      }

      if(groupProjects) {
        Object.keys(perEstate).forEach(key => {
          const estate = estateMap[key] || {};
          if(estate.parentId && estateMap[estate.parentId]) {
            perEstate[estate.parentId] = [...(perEstate[estate.parentId] || []), ...(perEstate[key])];
            perEstate[key] = [];
          }
        });
      }


      if(groupProjects && onlyOnline && onlineUnderOffline) {
        estateData.filter((e) => e.displayStatusId === 1 || e.statusId !== 1).forEach((e) => {
          delete perEstate[e.id];
        });
      }

      const data = Object.keys(perEstate).flatMap((e, i) =>{
        let estate = estateMap[e] || {};
        if(!estate.id) {
          console.log(e)
        }

        if (groupRepresentatives) {
          //console.log(estate.id, estate, perEstate[e].length)
          return ({
            index: `${i}`,
            estateId: e,
            address: `${estate.address1 || ''} ${estate.number || ''} ${!estate.address1 && !estate.number ? estate.name : ''} ${estate.isParent ? '(project)' : ''}`.trim(),
            zip: `${estate.zip || '-'}`,
            city:`${estate.city || '-'}`,
            representativeEstate: (estate.representatives.length ? this.state.representatives.find(rep => rep.id === estate.representatives[0].userId)?.nameOutgoingMail : null) || 'onbekend',
            estateCount: estate.isParent ? estate.estateCount : 1,
            leads: [...new Set(perEstate[e])].length,
            ratio: (estate.isParent ? estate.estateCount : 1) > 0 ? (Math.round(([...new Set(perEstate[e])].length / (estate.isParent ? estate.estateCount : 1)) * 10) / 10).toFixed(1).replace('.',',') : '0'
          });
        } else {
          const reps = perEstate[e]
            .map(c => contacts[c] && contacts[c].representativeIds && contacts[c].representativeIds.length ? contacts[c].representativeIds[0].value : 0)
            .map(r => {return (this.state.representatives.find(rep => rep.id === r) || {nameOutgoingMail: 'onbekend'}).nameOutgoingMail});
          //console.log(contacts);
          return  [...new Set(reps)].map((c, j) => ({
            index: `${i}-${j}`,
            estateId: e,
            address: `${estate.address1 || '-'} ${estate.number || '-'} ${estate.isParent ? '(project)' : ''}`.trim(),
            zip: `${estate.zip || '-'}`,
            city:`${estate.city || '-'}`,
            representative: c,
            representativeEstate: (estate.representatives.length ? this.state.representatives.find(rep => rep.id === estate.representatives[0].userId)?.nameOutgoingMail : null) || 'onbekend',
            estateCount: estate.isParent ? estate.estateCount : 1,
            leads: reps.filter(x => x === c).length,
            ratio: (estate.isParent ? estate.estateCount : 1) > 0 ? (Math.round((reps.filter(x => x === c).length / (estate.isParent ? estate.estateCount : 1)) * 10) / 10).toFixed(1).replace('.',',') : '0'
          }));
        }
      }).filter(entry => entry.leads > 0).sort((a,b) => b.leads - a.leads);

      console.log({perEstate, estateMap, data, activities})
      return data;
    }

    renderTable = () => {
      const {data} = this.state;
      const columns = [{
        title: 'Pandnr',
        key: 'estateId',
        dataIndex: 'estateId',
      }, {
        title: 'Adres',
        key: 'address',
        dataIndex: 'address',
      }, {
        title: 'Postcode',
        key: 'zip',
        dataIndex: 'zip',
      }, {
        title: 'Plaats',
        key: 'city',
        dataIndex: 'city',
      }, {
        title: 'Vertegenwoordiger Klant',
        key: 'representative',
        dataIndex: 'representative',
      }, {
        title: 'Vertegenwoordiger Woning',
        key: 'representativeEstate',
        dataIndex: 'representativeEstate',
      }, {
        title: 'Units',
        key: 'estateCount',
        dataIndex: 'estateCount',
      }, {
        title: 'Leads',
        key: 'leads',
        dataIndex: 'leads',
      }, {
        title: 'Leads/Unit',
        key: 'ratio',
        dataIndex: 'ratio',
      },{
        title: 'Leads Lopend Jaar',
        key: 'leadslj',
        dataIndex: 'leads',
      }, {
        title: 'Leads/Unit',
        key: 'ratiopu',
        dataIndex: 'ratio',
      },{
        title: 'Leads Maand',
        key: 'leadsMonth',
        dataIndex: 'leadsMonth',
      }, {
        title: 'Leads/Unit',
        key: 'ratioMonthpu',
        dataIndex: 'ratioMonth',
      },{
        title: 'Leads Kwartaal',
        key: 'leadsQuarter',
        dataIndex: 'leadsQuarter',
      }, {
        title: 'Leads/Unit',
        key: 'ratioQuarterpu ',
        dataIndex: 'ratioQuarter',
      }];
      if(this.state.groupRepresentatives) {
        columns.splice(4,1);
      }
      if(!this.state.useSetTimeFrames) {
        columns.splice(9,6);
      } else {
        columns.splice(7,2);
      }
      return <Table rowKey={(row)=>`lead-${row.index}`} pagination={{pageSize: 100}} columns={columns} dataSource={data} />
    }

    getCsvHeaders = () => {
      const headers = [
        {label: "Pandnr", key: "estateId"},
        {label: "Adres", key: "address"},
        {label: "Postcode", key: "zip"},
        {label: "Plaats", key: "city"},
        {label: "Vertegenwoordiger Klant", key: "representative"},
        {label: "Vertegenwoordiger Woning", key: "representativeEstate"},
        {label: "Units", key: "estateCount"},
        {label: "Leads", key: "leads"},
        {label: "Leads/Unit", key: "ratio"},
        {label: "Leads Lopend Jaar", key: "leads"},
        {label: "Leads/Unit", key: "ratio"},
        {label: "Leads Maand", key: "leadsMonth"},
        {label: "Leads/Unit", key: "ratioMonth"},
        {label: "Leads Kwartaal", key: "leadsQuarter"},
        {label: "Leads/Unit", key: "ratioQuarter"},
      ];
      if(this.state.groupRepresentatives)
        headers.splice(4,1);
      if(!this.state.useSetTimeFrames)
        headers.splice(9,6);
      else
        headers.splice(7,2);

      return headers;
    }

    getFileName = () => {
      if(!this.state.useSetTimeFrames) {
        return `leads-${this.state.from}-${this.state.to}${this.state.groupProjects ? '-projects' : ''}${this.state.groupRepresentatives ? '-representatives' : ''}.csv`;
      } else {
        return `leads-vergelijking-${moment(this.state.comparisonDate).format('YYYY-MM-DD')}.csv`;
      }
    }

    getSettingsState = () => {
      const {from, to, comparisonDate, groupProjects, groupRepresentatives, onlyOnline, onlineUnderOffline, useSetTimeFrames} = this.state;

      return JSON.stringify({from, to, comparisonDate, groupProjects, groupRepresentatives, onlyOnline, onlineUnderOffline, useSetTimeFrames});
    }

    render() {
        const {periods, activities, loading, from, to, comparisonDate, groupProjects, groupRepresentatives, onlyOnline, onlineUnderOffline, useSetTimeFrames, lastSettings} = this.state;
        return (
            <Fragment>
              <Title>Leads in Whise</Title>
              <Row>
                <Col xs={24} sm={12} md={8} lg={6} xl={4} style={{flexDirection: 'column', padding: '12px 24px'}}>
                  <label style={{display: 'block'}}>Vergelijk periodes</label>
                  <Switch onChange={(val) => this.setState({useSetTimeFrames: val})} checked={useSetTimeFrames} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4} style={{flexDirection: 'column', padding: '12px 24px'}}>
                  <label style={{display: 'block'}}>Groepeer vertegenwoordigers</label>
                  <Switch onChange={(val) => this.setState({groupRepresentatives: val})} checked={groupRepresentatives} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4} style={{flexDirection: 'column', padding: '12px 24px'}}>
                  <label style={{display: 'block'}}>Groepeer projecten</label>
                  <Switch onChange={(val) => this.setState({groupProjects: val})} checked={groupProjects} />
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4} style={{flexDirection: 'column', padding: '12px 24px'}}>
                  <label style={{display: 'block'}}>Alleen online panden</label>
                  <Switch onChange={(val) => this.setState({onlyOnline: val})} checked={onlyOnline} />
                </Col>
                {
                  onlyOnline && groupProjects ?
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} style={{flexDirection: 'column', padding: '12px 24px'}}>
                      <label style={{display: 'block'}}>Toon online panden onder offline projecten</label>
                      <Switch onChange={(val) => this.setState({onlineUnderOffline: val})} checked={onlineUnderOffline} />
                    </Col> : null
                }
              </Row>
              <Row>
                {
                  !useSetTimeFrames ? (
                    <>
                      <Col xs={24} sm={12} md={8} lg={6} xl={4} style={{flexDirection: 'column', padding: '12px 24px'}}>
                        <label style={{display: 'block'}}>Vanaf</label>
                        <DatePicker value={moment(from)} format={'DD-MM-YYYY'} onChange={(d) => this.setState({from: (d || moment()).format('YYYY-MM-DD')})} />
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6} xl={4} style={{flexDirection: 'column', padding: '12px 24px'}}>
                        <label style={{display: 'block'}}>Tot</label>
                        <DatePicker value={moment(to)} format={'DD-MM-YYYY'} onChange={(d) => this.setState({to: (d || moment()).format('YYYY-MM-DD')})} />
                      </Col>
                    </>
                  ) : (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} style={{flexDirection: 'column', padding: '12px 24px'}}>
                      <label style={{display: 'block'}}>Peildatum</label>
                      <DatePicker value={moment(comparisonDate)} format={'DD-MM-YYYY'} onChange={(d) => this.setState({comparisonDate: (d || moment()).format('YYYY-MM-DD')})} />
                    </Col>
                  )
                }
              </Row>
              {
                periods.length ? (
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={8} xl={6} style={{flexDirection: 'column', padding: '12px 24px'}}>
                      <List
                        header={<div>Periode{periods.length > 1 ? 's' : ''}</div>}
                        bordered
                        dataSource={periods}
                        renderItem={item => <List.Item>{item.from} tot {item.to}</List.Item>}
                      />

                    </Col>
                  </Row>
                ) : null
              }
              {
                lastSettings && !loading && lastSettings !== this.getSettingsState() ?
                  <>
                    <Alert
                      message="Let op!"
                      description="Uw instellingen zijn veranderd. De data in de tabel en export zullen afwijken van wat u verwacht. Genereer een nieuw rapport door opnieuw op 'Zoek Leads' te klikken."
                      showIcon
                      type="warning"
                    />
                  </> : null
              }
              <Row className="containerPadding">
                <Button type="primary" onClick={this.search} style={{marginRight: 20}}>Zoek Leads</Button>
                {
                  activities.length ? <>
                    <CSVLink
                      className="ant-btn ant-btn-primary"
                      data={this.state.data}
                      filename={this.getFileName()}
                      headers={this.getCsvHeaders()}
                      separator=";"
                    >
                      Download excel
                    </CSVLink>
                  </> : null
                }
              </Row>
              <Row className="containerPadding">
                {
                  loading ? <Spin size="large" /> : (
                    <>
                      {
                        activities.length ? (
                          <>
                            {this.renderTable()}
                          </>
                        ) : null
                      }
                    </>
                  )
                }
              </Row>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeadsPage));
