import {Button, Modal} from 'antd';
import ActivityForm from './ActivityForm';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {postActivity} from '../../lib/serverCommunication';
import * as moment from 'moment';

const ActivityModal = ({contact, estate, config, auth, onClose}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    selectedUserId: auth.user.user.whiseUserId,
    contacts: contact || config.contactId ? [{value: contact ? contact.id : config.contactId, rowStatus: 1}] : undefined,
    estates: estate || config.estateId ? [{value: estate ? estate.id : config.estateId, rowStatus: 1}] : undefined,
    historyDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
    subject: 'e-mail ontvangen',
    selectedActionId: "26380"
  });

  const handleOk = async () => {
    setLoading(true);
    await postActivity(data);
    onClose();
  };
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal title="Historiek toevoegen" visible onOk={handleOk} onCancel={handleCancel} width={800} footer={[
      <Button key="back" onClick={handleCancel}>
        Annuleer
      </Button>,
      <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
        Opslaan
      </Button>,
    ]}>
      <ActivityForm contactName={contact ? contact.name : config.contactName} estateName={estate ? estate.name : config.estateName} data={data} setData={setData} />

    </Modal>
  )
}


const mapStateToProps = state => ({
  config: state.config,
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModal);

