import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import './App.scss';
import DefaultLayout from "./layout/DefaultLayout";
import {connect, Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {store, persistor} from './redux/configureStore';
import MainPage from "./pages/MainPage";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter, Redirect
} from "react-router-dom";
import Login from "./pages/Login";
import {Icon} from 'antd';
import Flex from "./components/layout/Flex";
import {logoutUser} from "./redux/actions/Actions";
import {getProfile} from "./lib/serverCommunication";
import EstatesPage from './pages/EstatesPage';
import ContactsPage from './pages/ContactsPage';
import EstatePage from './pages/EstatePage';
import ContactPage from './pages/ContactPage';
import SearchPage from './pages/SearchPage';
import LeadsPage from './pages/LeadsPage';
import {parseJWT} from './lib/jwt';
import AutomationsPage from './pages/AutomationsPage';

const AuthRoutes = withRouter(connect(
    state => ({auth: state.auth}),
    dispatch => ({
        logout: () => dispatch(logoutUser())
    })
)(function AuthRoutes(props) {
    const [verifyingAuth, updateVerifyingAuth] = useState(true);

    useEffect(() => {
        if (!props.auth || !props.auth.jwt || !props.auth.user || !props.auth.user.user ||!props.auth.user.user.whiseUserId) {
          props.logout();
        } else {
          const jwt = parseJWT(props.auth.jwt);
          if(!jwt.exp || jwt.exp < (Math.floor((new Date() - 0)/ 1000))) {
            props.logout();
          } else {
            getProfile()
              .then(body => {
                if (body.user) {
                  updateVerifyingAuth(false);
                } else {
                  console.log('logout')
                  props.logout();
                }
              })
          }
        }

    });

    if (!props.auth.jwt) {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}
            />
        )
    }

    if (verifyingAuth) {
        return (
            <DefaultLayout verifying>
                <Flex center style={{width: '100%', height: '100%'}}>
                    <Icon type={'loading'} spin style={{fontSize: '3rem'}}/>
                </Flex>
            </DefaultLayout>
        )
    }

    return (
        <Router>
            <DefaultLayout>
                <Switch>
                    <Route exact path="/">
                      <MainPage/>
                    </Route>
                  <Route exact path="/search">
                    <SearchPage />
                  </Route>
                  <Route exact path="/leads">
                    <LeadsPage />
                  </Route>
                  <Route exact path="/estates">
                    <EstatesPage />
                  </Route>
                  <Route exact path="/estates/:id">
                    <EstatePage />
                  </Route>
                  <Route exact path="/contacts">
                    <ContactsPage />
                  </Route>
                  <Route exact path="/contacts/:id">
                    <ContactPage />
                  </Route>
                  <Route exact path="/automations">
                    <AutomationsPage />
                  </Route>
                </Switch>
            </DefaultLayout>
        </Router>
    )
}));

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Switch>
                        <Route path="/login">
                            <Login/>
                        </Route>
                        <Route path="/">
                            <AuthRoutes/>
                        </Route>
                    </Switch>
                </Router>
            </PersistGate>
        </Provider>
    );
}

export default App;
