import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Icon, Pagination, Row, Spin, Typography} from "antd";
import {withRouter} from "react-router-dom";
import {getDefaults, getEstates, getRepresentatives, getTerms} from '../lib/serverCommunication';
import {PlusOutlined} from '@ant-design/icons';
import EstateCard from './partials/EstateCard';

const {Title} = Typography;

class EstatesPage extends Component {

    state = {
        loaded: false,
        estates: [],
        page: 1,
        totalCount: 0,
        selected: null,
        expanded: false,
        terms: [],
        defaults: [],
        representatives: [],
    };

    componentDidMount() {
        this.loadRequiredData().then(()=>{
            this.loadData(1);
        });
    }

    async loadRequiredData() {
        const termsRequest = await getTerms();
        const defaultsRequest = await getDefaults();
        const representatives = await getRepresentatives();

        if(termsRequest.terms && defaultsRequest.defaults) {
            this.setState({terms: termsRequest.terms, defaults: defaultsRequest.defaults, representatives: representatives.Whise});
        }
    }

    loadData = (page = 1) => {
        getEstates(page).then(data => {
            this.setState({estates: data.Whise.responseData, loaded: true, totalCount: data.Whise.totalCount});
        })
    }

    render() {
        const {estates, loaded, totalCount, selected} = this.state;
        return (
            <Fragment>
                <Title>
                    Panden
                    {' '}
                    <Button className="vertical-align-middle" type="primary" shape="circle" title="+" onClick={()=>this.props.history.push('/estates/0')}>
                        <PlusOutlined/>
                    </Button>
                </Title>
                {
                    loaded ? <>
                        <Row>
                            <Col xs={24} md={8}>
                                <Pagination onChange={(page) => {
                                    this.setState({page, selected: null});
                                    this.loadData(page);
                                }} total={Math.ceil(totalCount / 100)} />
                                {
                                    estates.map((estate, index) => {
                                       return <div key={index} className="list-card" onClick={() => this.setState({selected: index})}>
                                           <Row>
                                               <Col xs={18}>
                                                   {`${estate.address1 || '-'} ${estate.number || '-'}, ${estate.zip || '-'} ${estate.city || '-'}`} <br />
                                                   {`${estate.name}`} <br/>
                                                   <em>{`${estate.comment || ''}`}</em>
                                               </Col>
                                               <Col xs={6} style={{textAlign: 'right'}}>
                                                   <Icon type="search" style={{fontSize: 28, color: '#232856'}} />
                                               </Col>
                                           </Row>
                                       </div>

                                    })
                                }
                                <Pagination onChange={(page) => {
                                    this.setState({page, selected: null});
                                    this.loadData(page);
                                }} total={Math.ceil(totalCount / 100)} />
                            </Col>
                            <Col xs={24} md={16} style={{paddingLeft: 16}}>
                                {
                                    selected !== null ?
                                      <EstateCard estate={estates[selected]} terms={this.state.terms} fields={this.state.defaults} representatives={this.state.representatives} /> :
                                      null
                                }
                            </Col>
                        </Row>
                    </> : <Spin />
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EstatesPage));
