import {Button, Card, Icon} from 'antd';
import React, {useState} from 'react';
import ObjectRender from './ObjectRender';
import {CheckOutlined, MinusOutlined} from '@ant-design/icons';
import {setEstate} from '../../redux/actions/Actions';
import {connect} from 'react-redux';

const fieldsIncluded = ['address1', 'number', 'categoryId', 'city', 'clientId', 'displayStatusId', 'name', 'purposeId', 'purposeStatusId', 'statusId', 'subcategoryId', 'zip', 'representatives'];

const EstateCard = ({estate, terms, representatives, fields, selectedEstateId, setEstate}) => {
  const [showAll, setShowAll] = useState(false);

  if(!estate) return (<></>);

  const filtered = Object.fromEntries(fieldsIncluded.map(key => [key, estate[key]]));
  const rest = Object.fromEntries(Object.keys(estate).filter(key => fieldsIncluded.indexOf(key) === -1).map(key => [key, estate[key]]));

  return (
    <>
      <Card title={`${estate.name} - ${estate.address1 || '-'} ${estate.number || '-'}, ${estate.zip || '-'} ${estate.city || '-'}`} extra={
        estate.id !== selectedEstateId ?
          <Button shape="circle" onClick={() => setEstate(estate.id, `${estate.name} - ${estate.address1 || '-'} ${estate.number || '-'}, ${estate.zip || '-'} ${estate.city || '-'}`)}>
            <MinusOutlined/>
          </Button> :
          <Button shape="circle" type="primary">
            <CheckOutlined/>
          </Button>
      }>
        <ObjectRender object={filtered} terms={terms} fields={fields}  representatives={representatives}/>

        <div style={{fontSize: 16}}  onClick={() => setShowAll(!showAll)} >
          <Icon type={showAll ? 'minus' : 'plus'} style={{fontSize: 16, color: '#232856', marginRight: 12}} />
          Bekijk alle gegevens
        </div>
      </Card>

      {
        showAll ? (
          <Card title={"Alle gegevens"}>
            <ObjectRender object={rest} terms={terms} fields={fields}  representatives={representatives}/>
          </Card>
        ) : null
      }
    </>
    )
}

const mapStateToProps = state => ({
  selectedEstateId: state.config.estateId,
});
const mapDispatchToProps = dispatch => ({
  setEstate: (id, name) => dispatch(setEstate(id, name))
});

export default connect(mapStateToProps, mapDispatchToProps)(EstateCard);
