import {Button, Card, Icon} from 'antd';
import React, {useState} from 'react';
import ObjectRender from './ObjectRender';
import automationStatus from '../../assets/enums/automationStatus';
import {useHistory} from 'react-router-dom';

const fieldsIncluded = ['contactOrigin', 'date', 'sourceContent', 'status', 'contactId'];

const Automationcard = ({automation, terms, representatives, fields}) => {
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();

  if(!automation) return (<></>);

  const filtered = Object.fromEntries(fieldsIncluded.filter(f => f !== 'sourceContent').map(key => [key, automation[key]]));

  filtered.status = automationStatus[filtered.status];

  const handlingContact = (key, value) => {
    if(key !== 'contactId')
      return false;

    return <Button onClick={() => history.push(`/contacts/${value}`)}>Ga naar contact</Button>
  };

  return (
    <>
      <Card title={automation.contactOrigin}>
        <ObjectRender object={filtered} terms={terms} fields={fields} representatives={representatives} specialHandling={handlingContact}/>


        <div style={{fontSize: 16}}  onClick={() => setShowAll(!showAll)} >
          <Icon type={showAll ? 'minus' : 'plus'} style={{fontSize: 16, color: '#232856', marginRight: 12}} />
          Bekijk activiteit gegevens
        </div>
      </Card>

      {
        showAll ? (
          <Card title={"Activiteit gegevens"} style={{marginTop: 16}}>
            <ObjectRender object={JSON.parse(automation.sourceContent)} terms={terms} fields={fields}  representatives={representatives}/>
          </Card>
        ) : null
      }
    </>
    )
}

export default Automationcard;
