import React, {useState} from 'react';
import {connect} from "react-redux";
import {authenticateUser} from "../redux/actions/Actions";
import {withRouter} from "react-router-dom";
import {getToken, loginUser} from "../lib/serverCommunication";
import {Button} from "antd";
import logo from '../assets/images/hyboma-logo.svg';

function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const onChangeHandler = fn => e => {
        if (error) {
            setError('');
        }
        fn(e.target.value);
    };

    const handleLogin = (event) => {
        if (event) {
            event.preventDefault()
        }
        setLoading(true);
        setError('');
        loginUser({email, password}).then(res => {
            if (res.error) {
                return setError(res.error);
            }
            const jwt = res.jwt;
            props.login(res);
            getToken().then(tokenRes => {
                console.log({user: res.user, jwt: jwt, whise: tokenRes.Whise.token})
                props.login({user: res.user, jwt: jwt, whise: tokenRes.Whise.token})
                try {
                    props.history.replace('/');
                } catch(e) {
                    window.location.href = '/';
                }
            })
        })
            .finally(() => setLoading(false));
    };

    return (
        <form onSubmit={handleLogin}>
            <div className="LoginView">
                <img src={logo} className='logo' alt="logo" />
                <div className="card">
                    <h1>Hyboma Portal</h1>
                    <p className="error">{error}</p>
                    <div className="login-form">
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={onChangeHandler(setEmail)}
                            placeholder="Email"
                        />
                    </div>
                    <div className="login-form">
                        <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={onChangeHandler(setPassword)}
                            placeholder="Wachtwoord"
                        />
                    </div>
                    <Button className="login-btn" onClick={handleLogin} loading={loading}>Login</Button>
                </div>
            </div>
        </form>
    )

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    login: user => dispatch(authenticateUser(user)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
