import React, {useState} from 'react';
import {setContact, setEstate} from '../../redux/actions/Actions';
import {connect} from 'react-redux';
import {Button} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import FitText from '@kennethormandy/react-fittext'

const CurrentSelections = ({config, clearContact, clearEstate}) => {
  const [expanded, setExpanded] = useState(true);
  if(!expanded) {
    return (
      <div className="current-selections-minimized" onClick={() => setExpanded(true)} />
    );
  }

  const nameContact = config.contactId ? config.contactName : 'Geen geselecteerd';
  const nameEstate = config.estateId ? config.estateName : 'Geen geselecteerd';

  const calcStyle = (text) => {
    const lines = text.length < 25 ? 1 : (text.length < 58 ? 2 : 3);
//    const lines = Math.ceil(text.length / 25);
    return {
      maxHeight: `26px`,
      lineHeight: `${26/lines}px`,
      fontSize: `${Math.max(Math.min(16,24/lines), 12)}px`,
    }
  }

  return (
    <div className="current-selections">
      <i onClick={() => setExpanded(false)}>-</i>
      <p>Geselecteerd</p>
      <div>
        Pand:
        {
          config.estateId ? (
            <Button shape="circle" size="small" onClick={clearEstate}><DeleteOutlined/></Button>
          ) : <></>
        }
      </div>
      <div>
        <p style={calcStyle(nameEstate)}>{nameEstate}</p>
      </div>
      <div>
        Contact:
        {
          config.contactId ? (
            <Button shape="circle" size="small" onClick={clearContact}><DeleteOutlined/></Button>
          ) : <></>
        }
      </div>
      <div>
        <p style={calcStyle(nameContact)}>{nameContact}</p>
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  config: state.config,
});
const mapDispatchToProps = dispatch => ({
  clearEstate: () => dispatch(setEstate(0, '')),
  clearContact: () => dispatch(setContact(0, ''))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentSelections);
