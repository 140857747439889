import {AUTHENTICATE, LOGOUT, SET_WHISE_TOKEN} from "../actions/Actions";

const initialState = {
    jwt: '',
    user: {
        email: '',
        firstname: '',
        lastname: '',
        accountType: '',
    },
};

const auth = (state = {...initialState}, action) => {
    switch (action.type) {
        case AUTHENTICATE:
            const user = action.payload;
            const jwt = `${user.jwt}`;
            delete user.jwt;

            return {
                ...state,
                accountActivated: true,
                user: {...user},
                jwt,
            };
        case SET_WHISE_TOKEN:
            return {
                ...state,
                user: {
                    ...state.user,
                    Whise: {
                        access_token: action.payload.Whise.access_token
                    }
                }
            }
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default auth;


