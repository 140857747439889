import React from 'react';

const ActivityForm = ({contactName, estateName, data, setData}) => {

  const setInput = (key) => (event) => {
    setData({...data, [key]: event.target.value});
  }
  const setSelect = (key) => (event) => {
    setData({...data, [key]: event.target.selectedOptions[0].value});
  }
  return (
    <>
      <div className="content-wrapper">
        <div className="section-content">

          <div className="row">
            <div className="col-sm-4 form-group dynamic-label">
              <label htmlFor="subject" data-i18n="Subject" className="capitalize">Onderwerp</label>
            </div>
            <div className="col-sm-8 form-group">
              <input type="text" id="subject" className="form-control" placeholder="Onderwerp" value={data.subject} onChange={setInput('subject')} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group dynamic-label">
              <label htmlFor="action" className="capitalize" data-i18n="TypeOfActions">Actietypes</label>
            </div>
            <div className="col-sm-8 form-group">
              <select id="action" className="form-control select2-hidden-accessible"
                      tabIndex="-1" aria-hidden="true" onChange={(event) => {
                        setSelect('selectedActionId')(event);
                        setInput('subject')({target: {value: event.target.selectedOptions[0].innerText}})
                      }}>
                <option></option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20483"
                        data-color="#FD2828">opendeur-openwerf
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20486"
                        data-color="#875519">telefoon
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20492"
                        data-color="#009933">afspraak
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="26380"
                        data-color="#FF906B" selected>e-mail ontvangen
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="28091"
                        data-color="#82B500">prijsofferte
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="28092"
                        data-color="#966100">voorlopige oplevering
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="28362"
                        data-color="#4650DA">immostaat opmaken
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="28799"
                        data-color="#E7E700">administratie
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="28835"
                        data-color="#FFFFFF">beurs
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="29695"
                        data-color="#FFFFFF">bezoek immo
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="29796"
                        data-color="#1E61F1">immo - inschrijven verkoopopdracht
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="29872"
                        data-color="#10C200">inkoopgesprek grond
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="30725"
                        data-color="#EAD900">e-mail verstuurd
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="37131"
                        data-color="#00DAE0">1ste afspraak
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20482"
                        data-color="#0066CC">bezoek
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="26231"
                        data-color="#FFFFFF">info bezorgd post
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20484"
                        data-color="#AFFD28">contract
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20485"
                        data-color="#FD9B28">andere
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20487"
                        data-color="#9518B0">vergadering
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20489"
                        data-color="#3399CC">schatting
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20490"
                        data-color="#990000">opleiding
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20491"
                        data-color="#336666">notaris
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20494"
                        data-color="#05E0F4">voorstelling
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20495"
                        data-color="#9999CC">permanentie
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20496"
                        data-color="#339900">verlof
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20497"
                        data-color="#6699FF">feestdag
                </option>
                <option data-bind="text: name, value: id, attr: {'data-color': color}" value="20498"
                        data-color="#FF00CC">prospectie
                </option>
              </select>
            </div>
          </div>


        </div>
        <div className="section-content">

          <div className="row">
            <div className="col-sm-4 form-group dynamic-label">
              <label htmlFor="contacts" data-i18n="With" className="capitalize">Met</label>
            </div>
            <div className="col-sm-8 form-group">
              <p>{contactName || 'Geen geselecteerd'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group dynamic-label">
              <label htmlFor="estates" data-i18n="Location" className="capitalize">Pand</label>
            </div>
            <div className="col-sm-8 form-group">
              <p>{estateName || 'Geen geselecteerd'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group dynamic-label">
              <label className="capitalize" htmlFor="description" data-i18n="Description">Beschrijving</label>
            </div>
            <div className="col-sm-8 form-group">
              <textarea id="description" rows="3" cols="50" className="form-control description"
                        data-i18n="[placeholder]Description" data-bind="value: description"
                        placeholder="Beschrijving" value={data.description} onChange={setInput('description')} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group dynamic-label">
              <label className="capitalize" htmlFor="internalDescription" data-i18n="InternalDescription">Interne
                beschrijving</label>
            </div>
            <div className="col-sm-8 form-group">
              <textarea id="internal-description" rows="1" cols="50" className="form-control"
                        data-i18n="[placeholder]InternalDescription" data-bind="value: internalDescription"
                        placeholder="Interne beschrijving" value={data.internalDescription} onChange={setInput('internalDescription')} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ActivityForm;
