import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Col, Row, Typography} from "antd";
import {Link, withRouter} from "react-router-dom";

const {Title} = Typography;

class MainPage extends Component {

    state = {};

    render() {
        return (
            <Fragment>
                <Title>Hyboma Whise Portal</Title>
                <Row>
                    <Col xs={24} md={12} xl={6}>
                        <Link to="/estates/0" className="dashboard-link">Pand toevoegen</Link>
                    </Col>
                    <Col xs={24} md={12} xl={6}>
                        <Link to="/contacts/0" className="dashboard-link">Contact toevoegen</Link>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPage));
