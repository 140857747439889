import React, {Fragment, useState} from 'react';
import { useLocation} from 'react-router-dom';
import Logo from "../assets/images/notores-logo";
import {Layout, Menu, Icon} from "antd";
import {Link} from "react-router-dom";

const {Sider,} = Layout;

function MenuItems(props) {
    // const dispatch = useDispatch();

    if (props.all) {
        return (
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[props.currentItem]} onClick={props.onMenuItemClick}>
                <Menu.Item key="/">
                    <Icon type="dashboard"/>
                    <span>Home</span>
                    <Link to={'/'}/>
                </Menu.Item>
                <Menu.Item key="/search">
                  <Icon type="search"/>
                  <span>Zoeken</span>
                  <Link to={'/search'}/>
                </Menu.Item>
                <Menu.Item key="/estates">
                  <Icon type="home"/>
                  <span>Panden</span>
                  <Link to={'/estates'}/>
                </Menu.Item>
                <Menu.Item key="/contacts">
                  <Icon type="user"/>
                  <span>Contacten</span>
                  <Link to={'/contacts'}/>
                </Menu.Item>
                <Menu.Item key="/leads">
                  <Icon type="team"/>
                  <span>Leads</span>
                  <Link to={'/leads'}/>
                </Menu.Item>
                <Menu.Item key="/automations">
                  <Icon type="robot"/>
                  <span>Automaties</span>
                  <Link to={'/automations'}/>
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[props.currentItem]} onClick={props.onMenuItemClick}>
            <Menu.Item key="/">
                <Icon type="dashboard"/>
                <span>Home</span>
                <Link to={'/'}/>
            </Menu.Item>
        </Menu>
    )
}

export default props => {
    let [currentItem, updateCurrentItem] = useState(useLocation().pathname || '/');

    const onMenuItemClick = ({item, key, keyPath}) => {
        updateCurrentItem(key);
    };

    if (currentItem.indexOf('/orders/process') > -1) {
        currentItem = '/';
    } else if (currentItem.startsWith('/products/')) {
        currentItem = '/products';
    }

    return (
        <Fragment>
            <Sider trigger={null} collapsible collapsed={props.collapsed}
                   style={{
                       overflow: 'auto',
                       height: '100vh',
                       position: 'fixed',
                       left: 0,
                   }}>
                <div className="flex row justify-content-center logo">
                    <Logo/>
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}>
                    <MenuItems all={!props.verifying} defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}/>
                </Menu>
            </Sider>
        </Fragment>
    )
};
