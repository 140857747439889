import React from 'react';
import {Table} from 'antd';

const LabelRender = ({fieldKey, terms}) => {
  const term = terms.find(t => t.field === fieldKey);
  if(term) return (<>{term.friendly}</>);
  return (<>{fieldKey}</>);
};

const ObjectRender = ({object, terms, representatives, fields, specialHandling}) => {
  return <dl>
    {
      [
        ...(Object.keys(object).filter(k => !Array.isArray(object))).sort(),
        ...(Object.keys(object).filter(k => Array.isArray(object))).sort()
      ].map(key => {
        const data = object[key];
        return <React.Fragment key={key}>
          <dt style={{fontWeight: 'bold'}}>
            <LabelRender fieldKey={key} terms={terms} />
          </dt>
          <dd style={{borderBottom: '2px solid #ffcc32', marginBottom: 8, paddingBottom: 8}}>
            <ValueRender value={data} terms={terms} fields={fields} fieldKey={key} representatives={representatives} specialHandling={specialHandling} />
          </dd>
        </React.Fragment>
      })
    }
  </dl>
};

const ValueRender = ({value, terms, representatives, fields, fieldKey, specialHandling}) => {
  if(value === undefined || value === null)
    return null;

  if(specialHandling) {
    const ret = specialHandling(fieldKey, value);
    if(ret !== false) return ret;
  }

  if(['contactTypeIds'].indexOf(fieldKey) > -1) {
    value = value.length > 0 ? value[0].value : 0;
    fieldKey = fieldKey.substring(0,fieldKey.length - 3) + 's';
  }

  if(fieldKey === 'representatives') {
    value = value.map(e => representatives.find(r => r.id === e.userId).nameOutgoingMail).join(',');
  }

  if(fieldKey === 'representativeIds') {
    value = value.map(e => representatives.find(r => r.id === e.value).nameOutgoingMail).join(',');
  }



  switch(typeof value) {
    case 'object':
      if(Array.isArray(value)) {
        if(value.length) {
          return <Table dataSource={value} columns={Object.keys(value[0]).map((k, i) => (
            {
              title: k,
              dataIndex: k,
              key: `${k}-${i}`,
              render: (val, index) => {
                return (<ValueRender value={val} terms={terms} fieldKey={k} fields={fields} representatives={representatives} specialHandling={specialHandling} />)
              }
            }))} />
        }
        return null
      } else {
        return <ObjectRender fields={fields} terms={terms} object={value} representatives={representatives} specialHandling={specialHandling}/>
      }
    case 'boolean':
      return value ? 'Ja': 'Nee';
    case 'number':
      if(fields[fieldKey]) {
        const val = fields[fieldKey].find(e => e.id === value);
        if(val) {
          return val.text;
        }
      }
      return value.toString();
    case 'string':
    default:
      return value.toString();

  }
};

export default ObjectRender;
