import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Icon, Pagination, Row, Spin, Typography} from "antd";
import {withRouter} from "react-router-dom";
import {
    getContacts,
    getDefaults,
    getRepresentatives,
    getTerms
} from '../lib/serverCommunication';
import {PlusOutlined} from '@ant-design/icons';
import ContactCard from './partials/ContactCard';

const {Title} = Typography;

class ContactsPage extends Component {

    state = {
        loaded: false,
        contacts: [],
        page: 1,
        totalCount: 0,
        selected: null,
        activities: [],
        representatives: [],
        loadDetails: true,
        expanded: false,
        terms: [],
        defaults: [],
    };

    componentDidMount() {
        this.loadRequiredData().then(()=>{
            this.loadData(1);
        });
    }

    async loadRequiredData() {
        const termsRequest = await getTerms();
        const defaultsRequest = await getDefaults();
        const representatives = await getRepresentatives();

        if(termsRequest.terms && defaultsRequest.defaults) {
            this.setState({terms: termsRequest.terms, defaults: defaultsRequest.defaults, representatives: representatives.Whise, loading: false});
        }
    }

    loadData = (page = 1) => {
        getContacts(page).then(data => {
            console.log('getContacts', data.Whise)
            this.setState({contacts: data.Whise.responseData, loaded: true, totalCount: data.Whise.totalCount});
        })
    }

    render() {
        const {contacts, loaded, totalCount, selected} = this.state;
        return (
          <Fragment>
              <Title>
                  Contacten
                  {' '}
                  <Button className="vertical-align-middle" type="primary" shape="circle" title="+" onClick={()=>this.props.history.push('/contacts/0')}>
                      <PlusOutlined/>
                  </Button>
              </Title>
              {
                  loaded ? <>
                      <Row>
                          <Col xs={24} md={8}>
                              <Pagination onChange={(page) => {
                                  this.setState({page, selected: null, expanded: false});
                                  this.loadData(page);
                              }} total={Math.ceil(totalCount / 100)} />
                              {
                                  contacts.map((contact, index) => {
                                      return <div key={index} className="list-card" onClick={() => {
                                          this.setState({selected: index})
                                      }}>
                                          <Row>
                                              <Col xs={18}>
                                                  {`${contact.name}, ${contact.firstName || '-'}`} <br/>
                                                  <span style={{fontVariant: 'normal', fontFeatureSettings: 'normal',fontSize: '12px'}}>{`${contact.privateEmail || '-'}, ${contact.privateMobile || '-'}`}</span>
                                              </Col>
                                              <Col xs={6} style={{textAlign: 'right'}}>
                                                  <Icon type="search" style={{fontSize: 28, color: '#232856'}} />
                                              </Col>
                                          </Row>
                                      </div>

                                  })
                              }
                              <Pagination onChange={(page) => {
                                  this.setState({page, selected: null});
                                  this.loadData(page);
                              }} total={Math.ceil(totalCount / 100)} />
                          </Col>
                          <Col xs={24} md={16} style={{paddingLeft: 16}}>
                              {
                                  selected !== null ?
                                    <ContactCard contact={contacts[selected]} terms={this.state.terms} fields={this.state.defaults} representatives={this.state.representatives}  />:
                                    <></>
                              }
                          </Col>
                      </Row>
                  </> : <Spin />
              }
          </Fragment>
        )
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactsPage));
