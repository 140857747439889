import React, {Fragment} from 'react';

export default function Flex(props) {
    const {
        children,
        className,
        hide, row, column, wrap, justifyContent, alignItems, center, grow, basis, stretch,
        ...localProps
    } = props;

    if (hide) {
        return (<Fragment/>)
    }

    const style = {
        display: 'flex',
        ...localProps.style
    };

    if (row) {
        style.flexDirection = 'row';
    }
    if (column) {
        style.flexDirection = 'column';
    }
    if (wrap) {
        style.flexWrap = wrap === true ? 'wrap' : wrap;
    }
    if (grow) {
        style.flexGrow = grow;
    }
    if (justifyContent) {
        style.justifyContent = justifyContent;
    }
    if (alignItems) {
        style.alignItems = alignItems;
    }
    if (center) {
        style.justifyContent = 'center';
        style.alignItems = 'center';
    }
    if (basis) {
        style.flexBasis = basis;
    }
    if (stretch) {
        style.justifyContent = 'stretch';
    }

    return (
        <div {...localProps} className={className || ''} style={style}>
            {children}
        </div>
    )
}
