import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Button, Typography} from "antd";
import {withRouter} from "react-router-dom";
import {getDefaults, postEstate} from '../lib/serverCommunication';

const {Title} = Typography;

const baseBody = {
  "name": "Naam van pand",
  "referenceNumber": "REF",
  "subcategoryId": 70,
  "categoryId": 1,
  "purposeStatusId": 1,
  "purposeId": 1,
  "displayStatusId": 1,
  "statusId": 1,
  "linkRouteDesc": false,
  "displayPrice": true,
  "makeOffer": true,
  "officeId": 8231,
  "address1": "Hoofdstraat",
  "number": "123",
  "floor": "1",
  "zip": "1234AB",
  "city": "Velp",
  "countryId": 2,
  "price": 123456,
  "regionIds": [],
  "fronts": 3,
  "stateId": 1,
  "availabilityId": 6,
  "availabilityDateTime": "2021-01-28T00:00:00",
  "smsML": [],
  "longDescriptionML": [],
  "shortDescriptionML": [],
  "publicationTextML": [],
  "subDetailValues": [],
  "referrers": [],
  "representatives": [],
  "representativeIns": [],
  "pictures": [],
  "documents": [],
  "contract": null,
  "owners": [],
  "buyers": [],
  "tenants": [],
  "notaries": [],
  "notariesBuyer": [],
  "contacts": [],
  "exports": [],
  "exportTexts": [],
  "rowStatus": 1
};

class EstatePage extends Component {

  formRef = null;

  state = {
    loaded: false,
    fieldData: {},
    catSelected: 0,
  };

  componentDidMount() {
    this.loadData().then((fieldData) => {
      const data = {...this.state.data};
      for(let key in fieldData) {
        data[key] = (fieldData[key].find(a => a.selected) || {}).id || baseBody[key];
      }
      this.setState({fieldData, loaded: true, data })
    });
  }

  loadData = async () => {
    const request = await getDefaults();
    return request.defaults;
  }

  submitForm = async () => {
    const form = this.formRef;
    const elements = form.querySelectorAll('input, select');
    const data = {}
    for(const ele of elements) {
      if(!ele.name) {
        console.log("missing name", ele)
      } else if(ele.nodeName === 'INPUT') {
        if(ele.type === 'checkbox') {
          data[ele.name] = !!ele.checked;
        } else {
          data[ele.name] = ele.value;
        }
      } else if(ele.nodeName === 'SELECT') {
        console.log(ele.name, ele.selectedIndex)
        data[ele.name] = ele.options[ele.selectedIndex].value
      }
    }

    if(data.regionId)
      data.regionIds = [data.regionId];
    else
      data.regionIds = [];
    delete data.regionId;

    console.log(data);
    if(!data.subcategoryId || !data.purposeStatusId) {
      this.setState({invalid: true});
    } else {
      const body = {
          ...baseBody,
          ...data
      };
      try {
        const response = await postEstate(body);
        console.log(response);
        this.props.history.push('/estates')
      } catch (e) {
        console.error(e);
      }
    }
  }

  onCategorySelect = (event) => {
    const value = event.currentTarget.options[event.currentTarget.selectedIndex].value;
    this.setState({catSelected: parseInt(value) || 0})
  }

  render() {
    const {loaded, fieldData, catSelected} = this.state;
    return (
      <Fragment>
        <Title>
          Pand toevoegen
        </Title>
        <form ref={(ref) => this.formRef = ref} onSubmit={(e) => {e.preventDefault(); return false;}}>
          <div className="section-content has-title is-editable" data-bind="click: sectionLocation.select">
            <div className="section-title icon-middle" data-bind="with: sectionLocation">
              <a data-bind="click: expand, css: { 'selected': isExpanded() }" className="selected">
                <i className="fa fa-angle-down fa-lg toggle-arrow"
  data-bind="css: {'fa-angle-down': isExpanded(), 'fa-angle-up': !isExpanded()}"/>
                <span data-bind="text: name" className="capitalize">Naam, Categorie &amp; Ligging</span>
              </a>
            </div>
            <div data-bind="visible: sectionLocation.isExpanded()">
              <div data-bind="visible: sectionLocation.isSelected()">
                <div className="row">
                  <div className="col-sm-2 form-group" data-bind="validationElement: name" data-orig-title="">
                    <label className="capitalize" data-i18n="EstateName">Naam van het pand</label>
                  </div>
                  <div className="col-sm-4 form-group">
                    <input type="text" data-bind="value: name" data-i18n="[placeholder]Name" className="form-control"
                           placeholder="Naam" />
                  </div>
                  <div className="col-sm-1 form-group">
                    <label className="capitalize" data-i18n="Reference">Referentienummer</label>
                  </div>
                  <div className="col-sm-3 form-group">
                            <span className="prefix-label" data-bind="visible: referencePrefix" >
                                <span data-bind="text: referencePrefix"/>:
                            </span>
                    <input type="text"
                           data-bind="value: referenceNumber, css: { 'inline-input': referencePrefix().length > 0 }"
                           data-i18n="[placeholder]Reference" className="form-control" placeholder="Referentienummer" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label data-i18n="Category" className="capitalize" data-bind="validationElement: categoryId"
                           data-orig-title="">Categorie</label>
                  </div>
                  <div className="col-sm-2 form-group"
                       data-bind="tooltip: { title: !isNew() ? 'CategoryDisabled' : '' }"
                       data-original-title="Categorie wijziging verboden voor bestaande panden">
                    <select className="form-control select2-hidden-accessible"
                            data-bind="options: categorySelection, value: categoryId, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Category'), disable: !isNew(), select: { }"
                            tabIndex="-1" aria-hidden="true" onChange={this.onCategorySelect}>
                      <option value="">Categorie</option>
                      {
                        loaded && fieldData.categoryId.map(e => (<option value={`${e.id}`} {...(e.selected ? {selected: true} : {})}>{e.text}</option>))
                      }
                    </select>
                  </div>
                  <div className="col-sm-3 form-group">
                    <select  className="form-control select2-hidden-accessible"
                            data-bind="options: subcategorySelection, value: subcategoryId, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Subcategory'), select: { }"
                            tabIndex="-1" aria-hidden="true" ref={(ref) => this.subCatRef = ref}>
                      <option value="">Subcategorie</option>
                      {
                        loaded && fieldData.subcategoryId.filter(e => e.group === catSelected).map(e => (<option value={`${e.id}`} {...(e.selected ? {selected: true} : {})}>{e.text}</option>))
                      }
                    </select>
                  </div>
                  <div className="col-sm-2 form-group" data-bind="visible: parentId()" >
                    <div className="checkbox icon-middle">
                      <input type="checkbox" data-bind="uniqueId: true, checked: isTypeEstate" id="e4" />
                        <label data-bind="uniqueFor: 'after'" data-i18n="TypeEstate" htmlFor="e4">Type</label>
                    </div>
                  </div>
                  <div className="col-sm-2 form-group">
                    <div className="checkbox icon-middle">
                      <input type="checkbox" data-bind="uniqueId: true, checked: newDevelopment" id="e5" />
                        <label
                          data-bind="uniqueFor: 'after', text: subdetailSelection[EstateSubdetail.NewDevelopment].label"
                          htmlFor="e5">pand in opbouw</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label className="capitalize" data-i18n="Condition">Staat van het pand</label>
                  </div>
                  <div className="col-sm-3 form-group">
                    <select  className="form-control select2-hidden-accessible"
                            data-bind="options: stateSelection, value: stateId, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Condition'), select: { }"
                            tabIndex="-1" aria-hidden="true">
                      <option value="">Staat van het pand</option>
                      {
                        loaded && fieldData.stateId.map(e => (<option value={`${e.id}`} {...(e.selected ? {selected: true} : {})}>{e.text}</option>))
                      }
                    </select>
                  </div>
                  <div className="col-sm-2 form-group">
                    <div className="checkbox icon-middle">
                      <input type="checkbox" data-bind="uniqueId: true, checked: greenEstate" id="e6" />
                        <label
                          data-bind="uniqueFor: 'after', text: subdetailSelection[EstateSubdetail.GreenEstate].label"
                          htmlFor="e6">groen panden</label>
                    </div>
                  </div>
                  <div className="col-sm-3 form-group">
                    <div className="checkbox icon-middle">
                      <input type="checkbox" data-bind="uniqueId: true, checked: investmentEstate" id="e7" />
                        <label data-bind="uniqueFor: 'after'" data-i18n="InvestmentEstate"
                               htmlFor="e7">Opbrengsteigendom</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label data-i18n="Fronts"
                           data-bind="visible: isHouse() || isAppartment() || isOffice() || isCommercial() || isIndustry()"
                           >Aantal gevels</label>
                  </div>
                  <div className="col-sm-4 form-group"
                       data-bind="visible: isHouse() || isAppartment() || isOffice() || isCommercial() || isIndustry()"
                       >
                    <select  className="form-control select2-hidden-accessible"
                            data-bind="options: frontsSelection, value: fronts, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Fronts'), select: { minimumResultsForSearch: Infinity }"
                            tabIndex="-1" aria-hidden="true">
                      <option value="">Aantal gevels</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 form-group relative">
                    <label className="capitalize">
                      <span data-i18n="Country">Land</span>
                    </label>
                  </div>
                  <div className="col-sm-3">
                    <select  className="form-control select2-hidden-accessible"
                            data-bind="options: countrySelection,value: countryId, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Country'), select: {}"
                            tabIndex="-1" aria-hidden="true">
                      <option value="">Land</option>
                      {
                        loaded && fieldData.countryId.map(e => (<option value={`${e.id}`} {...(e.selected ? {selected: true} : {})}>{e.text}</option>))
                      }
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 form-group relative" data-bind="validationElement: address"
                       data-orig-title="">
                    <label className="capitalize">
                      <span data-i18n="Street">Straat</span>
                    </label>
                  </div>
                  <div className="col-sm-10 form-group">
                    <div className="row">
                      <div className="col-sm-6">
                        <input type="text" data-bind="textInput: address" data-i18n="[placeholder]Street"
                               className="form-control address-autocomplete pac-target-input" placeholder="Straat"
                               autoComplete="off" />
                      </div>
                      <div className="col-sm-1">
                        <label className="capitalize" data-i18n="AddressNumber">Nummer</label>
                      </div>
                      <div className="col-sm-2">
                        <input data-bind="value: number" data-i18n="[placeholder]Number" type="text"
                               className="form-control" placeholder="Nummer" />
                      </div>
                      <div className="col-sm-1">
                        <label className="capitalize trim-overflow" data-i18n="Box" data-bind="tooltip: {title: 'Box'}"
                               data-original-title="Bus" title="">Bus</label>
                      </div>
                      <div className="col-sm-2">
                        <input type="text" data-bind="value: box" data-i18n="[placeholder]Box" className="form-control"
                               placeholder="Bus" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" data-bind="visible: address() &amp;&amp; Util.isRomanianUser()"
                     >
                  <div className="col-sm-2 form-group">
                    <label className="capitalize">
                      <span data-i18n="AddressDetails">Adresdetails</span>
                    </label>
                  </div>
                  <div className="col-sm-10 form-group">
                    <div className="row">
                      <div className="col-sm-12">
                        <input type="text" data-bind="value: addressDetails" data-i18n="[placeholder]AddressDetailsExp"
                               className="form-control" placeholder="Adresdetails" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row"
                     data-bind="visible: isHouse() || isAppartment() || isOffice() || isCommercial() || isGarage() || isIndustry()"
                     >
                  <div className="col-sm-2 form-group" data-bind="validationElement: address" data-orig-title="">
                    <label className="capitalize" htmlFor="floor">
                      <span data-i18n="Floor">Verdieping</span>
                    </label>
                  </div>
                  <div className="col-sm-10 form-group">
                    <div className="row">
                      <div className="col-sm-2">
                        <input type="text" id="floor" data-i18n="[placeholder]Floor" data-bind="value: floor"
                               className="form-control" placeholder="Verdieping" />
                      </div>
                      <div className="col-sm-4">
                        <label className="capitalize" data-i18n="NumberOfFloors">Aantal verdiepingen</label>
                      </div>
                      <div className="col-sm-2" data-bind="with: featuresVm.numberOfFloors()">
                        <input type="text" data-bind="value: numericValue" className="form-control"
                               data-i18n="[placeholder]NumberOfFloors" placeholder="Aantal verdiepingen" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label className="capitalize" htmlFor="zip">
                      <span data-i18n="Zip">Postcode</span> /
                      <span data-i18n="City">Stad</span>
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <div className="row form-group">
                      <div className="col-sm-2">
                        <input type="text" id="zip" data-i18n="[placeholder]Zip"
                               data-bind="value: zip"
                               className="form-control ui-autocomplete-input" autoComplete="off" placeholder="Postcode" />
                      </div>
                      <div className="col-sm-4">
                        <input type="text" data-i18n="[placeholder]City"
                               data-bind="value: city"
                               className="form-control ui-autocomplete-input" autoComplete="off" placeholder="Stad" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label className="capitalize">
                      <span data-i18n="Region">Regio</span>
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <div className="row form-group">
                      <div className="col-sm-9">
                        <select  className="form-control select2-hidden-accessible"
                                data-bind="options: regionSelection, value: regionId, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Region'), select: {}"
                                multiple="" tabIndex="-1" aria-hidden="true">
                          <option value="">Regio</option>
                          {
                            loaded && fieldData.regionId.map(e => (<option value={`${e.id}`} {...(e.selected ? {selected: true} : {})}>{e.text}</option>))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-3 checkbox">
                        <input type="checkbox" data-bind="uniqueId: true, checked: displayAddress" id="e8" />
                          <label data-bind="uniqueFor: 'after', tooltip: { title: 'DisplayAddressInfo' }"
                                 data-i18n="DisplayAddress" htmlFor="e8"
                                 data-original-title="If 'display address' is checked, we send the address, number, zip code and city to the media and the website designer. The general rule is that the exact address is shown in this case but this will depend on the media and on the website designer."
                                 title="">Toon adres</label>
                      </div>
                      <div className="col-sm-3 checkbox"
                           data-bind="visible: !Util.isRomanianUser()">
                        <input type="checkbox" data-bind="uniqueId: true, checked: linkRouteDesc" id="e9" />
                          <label data-bind="uniqueFor: 'after'" data-i18n="Plan" htmlFor="e9">Plan</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-content has-title is-editable" data-bind="click: sectionPriceTransaction.select">
            <div className="section-title icon-middle" data-bind="with: sectionPriceTransaction">
              <a data-bind="click: expand, css: { 'selected': isExpanded() }" className="selected">
                <i className="fa fa-angle-down fa-lg toggle-arrow"
  data-bind="css: {'fa-angle-down': isExpanded(), 'fa-angle-up': !isExpanded()}"/>
                <span data-bind="text: name" className="capitalize">Prijs &amp; transactie</span>
              </a>
            </div>
            <div data-bind="visible: sectionPriceTransaction.isExpanded()">
              <div data-bind="visible: sectionPriceTransaction.isSelected()">
                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label className="capitalize">
                      <span data-i18n="Transaction">Transactie</span>
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <div className="row">
                      <div className="col-sm-3 form-group"
                           data-bind="tooltip: { title: !isNew() ? 'PurposeDisabled' : '' }"
                           data-original-title="Transactieverandering verboden">
                        <select className="form-control select2-hidden-accessible"
                                data-bind="options: purposeSelection, value: purposeId, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Purpose'), disable: !isNew(), select: { }"
                                tabIndex="-1" aria-hidden="true" >
                          <option value="">Transactie</option>
                          <option value="1">te koop</option>
                          <option value="2">te huur</option>
                          <option value="3">lijfrente verkoop</option>
                        </select>
                      </div>
                      <div className="col-sm-3 form-group">
                        <select  className="form-control select2-hidden-accessible"
                                data-bind="options: purposeStatusSelection, value: purposeStatusId, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('PurposeStatus'), select: { }"
                                tabIndex="-1" aria-hidden="true">
                          <option value="">Status transactie</option>
                          {
                            loaded && fieldData.purposeStatusId.map(e => (<option value={`${e.id}`} {...(e.selected ? {selected: true} : {})}>{e.text}</option>))
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label className="capitalize">
                      <span data-bind="text: priceLabel">Prijs</span>
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <div className="row">
                      <div className="col-sm-3 form-group">
                        <input type="text" data-i18n="[placeholder]Price" data-bind="value: price"
                               className="form-control" placeholder="Prijs" />
                          <span className="unit unit-and-calculator" data-bind="text: currency">€</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label className="capitalize">
                      <span data-bind="text: soldPriceLabel">Verkoopprijs</span>
                    </label>
                  </div>
                  <div className="col-sm-10">
                    <div className="row">
                      <div className="col-sm-3 form-group">
                        <input type="text" data-bind="value: soldPrice" className="form-control" />
                          <span className="unit" data-bind="text: currency">€</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label className="capitalize">
                      <span data-i18n="Availability">Beschikbaarheid</span>
                    </label>
                  </div>
                  <div className="col-sm-10 form-group">
                    <div className="row">
                      <div className="col-sm-4">
                        <select  className="form-control select2-hidden-accessible"
                                data-bind="options: availabilitySelection, value: availabilityId, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Availability'), select: {}"
                                tabIndex="-1" aria-hidden="true">
                          <option value="">Beschikbaarheid</option>
                          {
                            loaded && fieldData.availabilityId.map(e => (<option value={`${e.id}`} {...(e.selected ? {selected: true} : {})}>{e.text}</option>))
                          }
                        </select>
                      </div>
                      <div className="col-sm-2 date-pick-wrapper"
                           data-bind="visible: availabilityId() != EstateAvailability.Immediately &amp;&amp; availabilityId() != EstateAvailability.NotAvailable">
                        <div className="input-group transparent date" data-bind="datepicker: availabilityDate">
                          <input type="text" className="form-control date-pick"
                                 data-i18n="[placeholder]AvailabilityDate" placeholder="Beschikbaar vanaf" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2 form-group">
                    <label className="capitalize">
                      <span data-i18n="Evaluation">Evaluatie</span>
                    </label>
                  </div>
                  <div className="col-sm-2 evaluation-form">
                    <select  className="form-control select2-hidden-accessible"
                            data-bind="options: evaluationSelection, value: evaluation, optionsText: 'name', optionsValue: 'id', optionsCaption: i18next.t('Evaluation'), select: {}"
                            tabIndex="-1" aria-hidden="true">
                      <option value="">Evaluatie</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <Button size="large" onClick={this.submitForm}>Opslaan</Button>
        </form>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EstatePage));
