import {applyMiddleware, createStore} from 'redux'

import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers'
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'config'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [loggerMiddleware,];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

export const store = createStore(persistedReducer, composedEnhancers);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
}

// let store = createStore(persistedReducer);
export let persistor = persistStore(store);
// }
