import {store} from './../redux/configureStore';
import {logoutUser, setWhisetoken} from '../redux/actions/Actions';

const serverUrl = 'https://whise-server.hyboma.be';
//const serverUrl = 'http://localhost:8080';

const genFetch = async (url, options, retryAttempts = 1, tokenOverwrite = null) => {
    let address;
    if (typeof url === 'string') {
        address = serverUrl + url;
    } else if (typeof url === 'object') {
        if (url.url.startsWith('http')) {
            address = url.url;
        } else {
            if (url.admin) {
                //
                address = `${serverUrl}/n-admin${url.url}`;
            } else {
                address = `${serverUrl}${url.url}`;
            }
        }
    }

    if(tokenOverwrite) {
        options.headers['x-whise-token'] = tokenOverwrite;
    }

    const request = await fetch(address, options);
    const response = await request.json();
    if(retryAttempts > 0 && response && response.Whise && response.Whise.e && response.Whise.e === 'Request failed with status code 401') {
        const newtoken = await getToken();
        store.dispatch(setWhisetoken(newtoken));
        return genFetch(url, options, 0, newtoken.Whise.access_token)
    } else {
        if(response && response.error && response.error ==='Not Authenticated') {
            store.dispatch(logoutUser());
        }
        return response;
    }
}

const genOptions = (method = 'GET', body, auth = true) => {
    const options = {
        method: method.toUpperCase(),
        credentials: 'omit',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };

    if (auth) {
        const state = store.getState();
        const auth = state.auth;
        if (auth.jwt)
            options.headers.Authorization = `Bearer ${auth.jwt}`;
        if (auth.user.whise)
            options.headers['x-whise-token'] = auth.user.whise.access_token;
    }

    if (!['GET', 'DELETE'].includes(method)) {
        options.body = JSON.stringify(body);
    }

    return options;
};

/*******************************
 ************* USER ************
 *******************************/

export const getProfile = () => genFetch('/profile', genOptions());

export const loginUser = ({email, password}) => {
    const body = {
        email,
        password
    };

    return genFetch('/login', genOptions('post', body, false));
};

/*******************************
 *********** PRODUCTS **********
 *******************************/

export const getToken = () => genFetch({url: `/whise/token`}, genOptions());
export const getContacts = (page = 1) => genFetch({url: `/whise/contacts/${page}`}, genOptions());
export const getContact = (id) => genFetch({url: `/whise/contact/${id}`}, genOptions());
export const postContact = (body) => genFetch({url: '/whise/contacts'}, genOptions('POST', body));
export const getEstates = (page = 1) => genFetch({url: `/whise/estates/${page}`}, genOptions());
export const getEstateParents = (ids) => genFetch({url: `/whise/estate-parents?ids=${ids.join(',')}`}, genOptions());
export const getEstateChildren = (ids) => genFetch({url: `/whise/estate-children?ids=${ids.join(',')}`}, genOptions());
export const postEstate = (body) => genFetch({url: '/whise/estates'}, genOptions('POST', body));
export const getActivities = (id) => genFetch({url: `/whise/activities/${id}`}, genOptions());
export const postActivity = (body) => genFetch({url: '/whise/activities'}, genOptions('POST', body));
export const postSearch = (body) => genFetch({url: `/whise/search`}, genOptions('POST', body));
export const getRepresentatives = () => genFetch({url: `/whise/representatives`}, genOptions());
export const getAutomations = () => genFetch({url: '/whise/automation'}, genOptions());

export const getDefaults = () => genFetch({url: '/defaults/fields'}, genOptions());
export const getTerms = () => genFetch({url: '/terms'}, genOptions());

export const getLeads = (from, to) => genFetch({url: `/whise/leads/${from}${to ? `/${to}` : ''}`}, genOptions());
