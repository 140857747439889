/*
 * action types
 */
export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const SET_WHISE_TOKEN = 'SET_WHISE_TOKEN';
export const SET_CONTACT_SELECTION = 'SET_CONTACT_SELECTION';
export const SET_ESTATE_SELECTION = 'SET_ESTATE_SELECTION';

export function authenticateUser(user) {
    return {type: AUTHENTICATE, payload: user};
}
export function setWhisetoken(token) {
    return {type: SET_WHISE_TOKEN, payload: token};
}
export function logoutUser() {
    return {type: LOGOUT};
}
export function setEstate(id, name) {
    return {type: SET_ESTATE_SELECTION, payload: {estateId: id, estateName: name}};
}
export function setContact(id, name) {
    return {type: SET_CONTACT_SELECTION, payload: {contactId: id, contactName: name}};
}
