import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Col, Icon, Pagination, Row, Spin, Typography} from "antd";
import {withRouter} from "react-router-dom";
import {
    getAutomations,
    getDefaults,
    getRepresentatives,
    getTerms
} from '../lib/serverCommunication';
import moment from 'moment';
import AutomationCard from './partials/AutomationCard';
import automationStatus from '../assets/enums/automationStatus';


const {Title} = Typography;

class AutomationsPage extends Component {

    state = {
        loaded: false,
        automations: [],
        page: 1,
        totalCount: 0,
        selected: null,
        activities: [],
        representatives: [],
        loadDetails: true,
        expanded: false,
        terms: [],
        defaults: [],
    };

    componentDidMount() {
        this.loadRequiredData().then(()=>{
            this.loadData(1);
        });
    }

    async loadRequiredData() {
        const termsRequest = await getTerms();
        const defaultsRequest = await getDefaults();
        const representatives = await getRepresentatives();

        if(termsRequest.terms && defaultsRequest.defaults) {
            this.setState({terms: termsRequest.terms, defaults: defaultsRequest.defaults, representatives: representatives.Whise, loading: false});
        }
    }

    loadData = (page = 1) => {
        getAutomations(page).then(data => {
            this.setState({automations: data.Whise, totalCount: data.Whise.length, loaded: true});
        })
    }

    render() {
        const {automations, loaded, totalCount, selected, page} = this.state;
        const paged = [...automations].slice(page * 25, totalCount > (page + 1) * 25 ? (page + 1) * 25 : totalCount);
        return (
          <Fragment>
              <Title>
                  Automaties
              </Title>
              {
                  loaded ? <>
                      <Row>
                          <Col xs={24} md={8}>
                              <Pagination onChange={(page) => {
                                  this.setState({page, selected: null, expanded: false});
                              }} total={Math.ceil(totalCount)} pageSize={25} hideOnSinglePage defaultCurrent={page} />
                              {
                                  paged.map((automation, index) => {
                                      return <div key={index} className="list-card" onClick={() => {
                                          this.setState({selected: index})
                                      }}>
                                          <Row>
                                              <Col xs={18}>
                                                  {`${automation.contactOrigin}`} <br/>
                                                  {moment(automation.date).format('DD/MM/YYYY HH:mm')}<br/>
                                                  Status: {automationStatus[automation.status]}
                                              </Col>
                                              <Col xs={6} style={{textAlign: 'right'}}>
                                                  <Icon type="search" style={{fontSize: 28, color: '#232856'}} />
                                              </Col>
                                          </Row>
                                      </div>

                                  })
                              }
                              <Pagination onChange={(page) => {
                                  this.setState({page, selected: null, expanded: false});
                              }} total={Math.ceil(totalCount / 25)} pageSize={25} hideOnSinglePage defaultCurrent={page} />
                          </Col>
                          <Col xs={24} md={16} style={{paddingLeft: 16}}>
                              {
                                  selected !== null ?
                                    <AutomationCard automation={automations[selected]} terms={this.state.terms} fields={this.state.defaults} representatives={this.state.representatives}  />:
                                    <></>
                              }
                          </Col>
                      </Row>
                  </> : <Spin />
              }
          </Fragment>
        )
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AutomationsPage));
